import React, { Component } from 'react'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	dialpad: {
		padding:		10,
		border:			'1px solid gray',
		borderRadius:	4,
		display:		'flex',
		flexDirection:	'column',
		boxShadow:		'0 5px 5px gray'
	},
	row: {
		display:		'flex',
		justifyContent:	'space-between',
		'&:not(:last-child)': {
			marginBottom: 3
		}
	},
	item: {
		padding:		5,
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'center',
		alignItems:		'center',
		width:			60,
		height:			55,
		lineHeight:		1,
		borderRadius:	4,
		color:			'#585858',
		cursor:			'pointer',
		'&:hover': {
			color:		theme.palette.primary.main,
			background:	'#efefef'
		},
		'&:not(:last-child)': {
			marginRight: 3
		}
	},
	digit: {
		fontSize:		30,
		marginBottom:	3
	},
	letters: {
		fontSize:	9,
		fontWeight:	500,
		height:		9,
		color:		'darkgray'
	}
})

const numberLettersMap = {1: ' ', 2: 'ABC', 3: 'DEF', 4: 'GHI', 5: 'JKL', 6: 'MNO', 7: 'PRQS', 8: 'TUV', 9: 'WXYZ', 0: ' ', '+': ' ', new_line: ' '}

class Dialpad extends Component {

	renderKey = char => {
		const { classes } = this.props
		return <div className={classes.item} onClick={() => this.props.onClick(char)}>
			<div className={classes.digit}>{char === 'new_line' ? String.fromCharCode('8629') : char}</div>
			<div className={classes.letters}>{numberLettersMap[char]}</div>
		</div>
	}

	render() {
		const { classes } = this.props
		return (
			<Popper open={this.props.open} anchorEl={this.props.anchorEl} placement='bottom'>
				<ClickAwayListener onClickAway={this.props.onClickAway}>
					<Paper classes={{root: classes.dialpad}}>
						<div className={classes.row}>
							{this.renderKey(1)}
							{this.renderKey(2)}
							{this.renderKey(3)}
						</div>
						<div className={classes.row}>
							{this.renderKey(4)}
							{this.renderKey(5)}
							{this.renderKey(6)}
						</div>
						<div className={classes.row}>
							{this.renderKey(7)}
							{this.renderKey(8)}
							{this.renderKey(9)}
						</div>
						<div className={classes.row}>
							{this.renderKey('+')}
							{this.renderKey(0)}
							{this.renderKey('new_line')}
						</div>
					</Paper>
				</ClickAwayListener>
			</Popper>
		)
	}
}

export default withStyles(styles)(Dialpad)