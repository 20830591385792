import React, {Component} from 'react'
import formatPhoneNumber from '../../util/phone_number_format'
import PhoneComUser from '../../util/PhoneComUser'
import CustomEvents from '../../util/customEvents'
import { DefaultArrowTooltip } from 'tooltips'

// This is meant to be extended when more features roll out
class ClickableContact extends Component {

    constructor(props) {
        super(props)
        this.number = this.props.num;
    }

    call = () => {
        if (PhoneComUser.getFeatures().includes('messages_unstable')) {
            let call = window.confirm(`Call ${this.number}?`)
            if (call) {
                CustomEvents.trigger('call', this.number)
            }
        }
    }

    componentWillReceiveProps(props) {
        this.number = props.num
    }

    render() {
		let label = this.props.name || this.number
		let formattedNumber = formatPhoneNumber(this.number)
        label = formatPhoneNumber(label)
        return (
			<DefaultArrowTooltip
				title			= {formattedNumber}
				placement		= 'right'
				interactive		= {true}
				leaveDelay		= {1}
				enterTouchDelay	= {0}
			>
            	<span className='clickable-contact-label fs-block' onDoubleClick={this.call}>{label}</span>
			</DefaultArrowTooltip>
        )
    }
}

export default ClickableContact