export function switchConversation(conversation) {
	return {
		type: 'SWITCH_CONVERSATION',
		conversation
	}
}

export function updateConversations(conversations) {
	return {
		type: 'UPDATE_CONVERSATIONS',
		conversations
	}
}

export function updateConversation(conversation) {
	return {
		type: 'UPDATE_CONVERSATION',
		conversation
	}
}

export function addConversation(conversation) {
	return {
		type: 'ADD_CONVERSATION',
		conversation
	}
}


export function addConversations(conversations) {
	return {
		type: 'ADD_CONVERSATIONS',
		conversations
	}
}

export function deleteConversation(conversation) {
	return {
		type: 'DELETE_CONVERSATION',
		conversation
	}
}


// Update messages
export function updateMessages(messages, conversation_id) {
	return {
		type: 'UPDATE_MESSAGES',
		messages,
		conversation_id
	}
}

// Add single message
export function addMessage(message, conversation_id, unread_messages) {
	return {
		type: 'ADD_MESSAGE',
		message,
		conversation_id,
		unread_messages
	}
}

// Set message as not pending and add its media (if any) in the redux state
export function updateMessage(message, conversation_id) {
	return {
		type: 'UPDATE_MESSAGE',
		message,
		conversation_id
	}
}

// Update message's delivery status. 'by' can be either 'message_id' either 'tag'
export function updateMessageStatus(by, flag, conversation_id, recipients_info) {
	return {
		type: 'UPDATE_MESSAGE_STATUS',
		flag,
		conversation_id,
		recipients_info,
		by
	}
}

// Delete single message. 'by' can be either 'message_id' either 'tag'
export function deleteMessage(by, flag, conversation_id) {
	return {
		type: 'DELETE_MESSAGE',
		flag,
		conversation_id,
		by
	}
}

export function updateMediaInfo(media_info, conversation_id) {
	return {
		type: 'UPDATE_MEDIA_INFO',
		media_info,
		conversation_id
	}
}


export function errorMessage(message) {
	return {
		type: 'ERROR_MESSAGE',
		message,
	}
}

export function updateConversationTotal(total, conversation_id) {
	return {
		type: 'UPDATE_TOTAL',
		total,
		conversation_id
	}
}

export function switchSendNumber(number) {
	return {
		type: 'SWITCH_SEND_NUMBER',
		number
	}
}

export function updateTotalConversations(total) {
	return {
		type: 'UPDATE_TOTAL_CONVERSATIONS',
		total
	}
}

export function addContactsToConversations(contacts) {
	return {
		type: 'ADD_CONTACTS_TO_CONVERSATIONS',
		contacts
	}
}

export function removeContactFromConversations(contactId) {
	return {
		type: 'REMOVE_CONTACT_FROM_CONVERSATIONS',
		contactId
	}
}