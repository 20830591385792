import React, {Component} from 'react'
import LoaderFull from 'loader-full'
import { DefaultArrowTooltip } from 'tooltips'
import { formatPhoneNumber } from 'phone-numbers'
import { AsYouType } from 'libphonenumber-js'
import PDCButton from 'pdc-button'

import Api from 'api'
import { getEmptyContactObject } from './ContactUtil'
import ConfirmModal from 'confirm-modal'
import { AddContactIcon, PlusCircleIcon } from 'pdc-svg-icons'

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'

const defaultFontFamily = 'ProximaNova, Helvetica, arial, sans-serif !important'

const styles = theme => ({
	addDialog: {
		padding:		'20px 40px',
		borderRadius:	10
	},
	addDialogContent: {
		padding:		'0 !important',
		marginTop:		0,
		marginBottom:	30
	},
	addDialogFooter:	theme.confirmDialog.dialogFooter,
	loadingDiv:			theme.loadingDiv,
	dialogRoot: {
		backgroundColor: 'initial'
	},
	dialog:	{
		width:			400,
		minHeight:		400,
		borderRadius:	10,
		fontFamily:		theme.fontFamily || defaultFontFamily
	},
	dialogContent: {
		padding: '25px 33px !important'
	},
	header: {
		display:		'flex',
		alignItems:		'center',
		fontSize:		20,
		lineHeight:		1.3,
		letterSpacing:	-0.2,
		marginBottom:	10,
		color:			theme.contactModal.titleColor
	},
	contactIcon: {
		marginRight:	10,
		width:			50,
		height:			50
	},
	mainSection: {},
	dataSection: {
		padding:		'8px 0 20px',
		borderRadius:	4,
		position:		'relative',
		transition:		'all .4s',
		maxHeight:		500,
		'&:not(:first-child)': {
			marginTop:	12
		},
		'&.collapsed': {
			maxHeight:		15,
			overflow:		'hidden',
			marginBottom:	20,
			'& .label': {
				top: -1
			},
			'& > *:not(.label)': {
				opacity: 0
			}
		},
		'& .label': {
			position:	'absolute',
			top:		-9,
			display:	'flex',
			alignItems:	'center',
			fontFamily:	theme.fontFamily || defaultFontFamily,
			fontSize:	13,
			lineHeight:	1.38,
			background:	'white',
			padding:	'0 4px',
			transition:	'all .4s',
			zIndex:		1,
			'&.clickable': {
				top:			-13,
				cursor:			'pointer',
				paddingRight:	0,
				'&:hover': {
					color: 'gray'
				}
			},
			'&.first-label': {
				left: -3
			},
			'&.second-label': {
				right:		'50%',
				transform:	'translateX(calc(100% - 15px))'
			},
			'& .highlight': theme.pdcInput.label.active
		}
	},
	inputWrapper: {
		marginTop:	8,
		transition:	'all .4s'
	},
	twoInputsWrapper: {
		display: 'flex',
		'& > *:first-child': {
			marginRight: 10
		}
	},
	phoneNumberSection: {
		marginTop:	6,
		display:	'flex',
		alignItems:	'center',
		'& > div:not(:last-child)': {
			marginRight: 10
		}
	},
	emailWrapper: {
		marginTop:	6,
		display:	'flex',
		alignItems:	'center',
		'& > div:first-child': {
			marginRight: 10
		}
	},
	textField: {
		'& input': {
			padding:	'8px 0 !important',
			fontFamily:	theme.fontFamily || defaultFontFamily
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '8px 0 !important'
		},
		'& .MuiInputBase-adornedEnd': {
			padding: '0px !important'
		},
		'& .MuiInputLabel-root': Object.assign({
			fontFamily: theme.fontFamily || defaultFontFamily
		}, theme.pdcInput.label),
		'& .MuiInputLabel-shrink': theme.pdcInput.label.active,
		'& .MuiInput-underline:after': {
			borderBottomColor: 'black'
		}
	},
	optionText: {
		'&.custom': {
			color: 'gray'
		}
	},
	removeIcon: {
		fontSize:	24,
		color:		'rgba(160, 0, 0, 0.6)',
		cursor:		'pointer',
		'&:hover:not(.not-allowed)': {
			color:	'rgba(160, 0, 0, 1)'
		},
		'&.not-allowed': {
			cursor:	'not-allowed',
			color:'rgba(160, 0, 0, 0.3)'
		}
	},
	addButton: {
		display:	'flex',
		alignItems:	'center',
		width:		'fit-content',
		padding:	'8px 0 0',
		cursor:		'pointer',
		color:		'gray',
		transition:	'all .4s',
		'&:hover': {
			color:	theme.palette.primary.main
		}
	},
	addIcon: {
		fontSize:		24,
		marginRight:	10,
		transition:		'all .4s'
	},
	footer: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'space-between',
		marginTop:		10
	},
	buttonsGroup: {
		display: 'flex'
	}
})

class EditContactModal extends Component {

	constructor(props) {
		super(props)
		this.state = Object.assign({
			loading:			true,
			homeHidden:			true,
			businessHidden:		true,
			basicInfoHidden:	true,
			showDeleteModal:	false,
			showAddTypeModal:	false,
			newType:			'',
			numberTypes:		['business', 'home', 'mobile', 'fax', 'custom'],
			contactGroupTypes:	[]
		}, this._getEmptyContactObject())
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contact !== this.props.contact || prevProps.type !== this.props.type) return this.init()
	}

	init = () => {
		let loading				= this.props.type === 'Edit' && !this.props.contact
		let contact				= (this.props.type === 'Edit' && this.props.contact) ? this.props.contact : this._getEmptyContactObject()
		let name				= JSON.parse(JSON.stringify(contact.name))
		let numbers				= JSON.parse(JSON.stringify(contact.numbers.filter(n => n.number)))
		let emails				= JSON.parse(JSON.stringify(contact.emails))
		let organization		= contact.organization
		let job_title			= contact.job_title
		let notes				= contact.notes
		let addresses			= JSON.parse(JSON.stringify(contact.addresses))
		let group				= JSON.parse(JSON.stringify(contact.group))
		let contactGroupTypes	= JSON.parse(JSON.stringify(this.props.contactGroupTypes))
		contactGroupTypes.push({id: null, name: 'custom'})
		this.setState({name, numbers, emails, organization, job_title, notes, addresses, group, contactGroupTypes}, () => this.setState({loading}))
	}

	_getEmptyContactObject = () => {
		let emptyContactObject = getEmptyContactObject()
		if (this.props.fixedNumber) {
			emptyContactObject.numbers.push({
				number:	this.props.fixedNumber,
				type:	'home',
				fixed:	true
			})
		}
		return emptyContactObject
	}

	renderInput = (index, multiline, placeholder, showLabel, value, disabled, onChange, onFocus, autocompleteValues, required = false) => {
		const { classes } = this.props
		let textFieldProps = {
			className:	classes.textField,
			variant:	'standard',
			fullWidth:	true,
			required: required
		}
		if (showLabel) textFieldProps.label = placeholder
		return autocompleteValues ? 
			<Autocomplete
				id				= {`${index}-${placeholder.split(' ').join('-').toLowerCase()}`}
				options			= {autocompleteValues}
				value			= {value}
				onChange		= {onChange}
				getOptionLabel	= {o => o && o.value ? `${o.value[0].toUpperCase()}${o.value.substr(1)}` : ''}
				style			= {{width: '100%'}}
				disabled		= {disabled}
				renderOption	= {o => (
					<React.Fragment>
						<DefaultArrowTooltip
							title		= {o.value === 'custom' ? 'Add your own' : ''}
							placement	= 'left'
						>
							<div id={o.key} className={`${classes.optionText} ${o.value === 'custom' ? 'custom' : ''}`}>
								{`${o.value[0].toUpperCase()}${o.value.substr(1)}`}
							</div>
						</DefaultArrowTooltip>
					</React.Fragment>
				)}
				renderInput		= {params => (
					<TextField
						{...params}
						InputProps	= {{
							...params.InputProps,
							placeholder: showLabel ? '' : placeholder
						}}
						{...textFieldProps}
					/>
				)}
			/>
			: <TextField
				id			= {`${index}-${placeholder.split(' ').join('-').toLowerCase()}`}
				value		= {value}
				placeholder	= {!showLabel ? placeholder : null}
				onChange	= {onChange}
				multiline	= {multiline}
				InputProps	= {{onFocus}}
				disabled	= {disabled}
				{...textFieldProps}
			/>
	}

	onNameChange	= (e, type) => {
		let value	= e.target.value
		let name	= this.state.name
		name[type]	= value
		this.setState({name})
	}
	onGroupChange	= group => {
		if (!group) group = {id: null, name: ''}
		if (group.value === 'custom') return this.setState({showAddTypeModal: 'group'})
		group = {
			id:		group.key,
			name:	group.value
		}
		this.setState({group})
	}
	onOrganizationChange	= e => this.setState({organization: e.target.value})
	onJobTitleChange		= e => this.setState({job_title: e.target.value})
	onNumberChange	= (e, i) => {
		let number			= e.target.value
		let numbers			= this.state.numbers
		numbers[i].number	= number
		this.setState({numbers})
	}
	onNumberTypeChange = (type, i) => {
		if (!type) type = {value: ''}
		if (type.value === 'custom') return this.setState({showAddTypeModal: 'number'})
		let numbers		= this.state.numbers
		numbers[i].type	= type.value
		this.setState({numbers})
	}
	onEmailChange	= (e, i) => {
		let email	= e.target.value
		let emails	= this.state.emails
		emails[i]	= email
		this.setState({emails})
	}
	onAddressChange	= (e, addressType) => {
		let address				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.address	= address
		this.setState({addresses})
	}
	onCityChange	= (e, addressType) => {
		let city			= e.target.value
		let addresses		= this.state.addresses
		let stateAddress	= addresses[addressType]
		stateAddress.city	= city
		this.setState({addresses})
	}
	onStateChange	= (e, addressType) => {
		let state			= e.target.value
		let addresses		= this.state.addresses
		let stateAddress	= addresses[addressType]
		stateAddress.state	= state
		this.setState({addresses})
	}
	onCountryChange	= (e, addressType) => {
		let country				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.country	= country
		this.setState({addresses})
	}
	onZipCodeChange	= (e, addressType) => {
		let zipCode				= e.target.value
		let addresses			= this.state.addresses
		let stateAddress		= addresses[addressType]
		stateAddress.zip_code	= zipCode
		this.setState({addresses})
	}

	formatNumberAsYouType = number => {
		if (number.length < 2) return number
		if (number[0] === '+' && number[1] !== '1') {
			return formatPhoneNumber(number)
		}
		if (number.substring(0, 2) === '+1') number = number.substring(2)
		return new AsYouType('US').input(number)
	}

	addPhoneNumberField = () => {
		let numbers		= this.state.numbers
		let newIndex	= numbers.length
		numbers.push({number: '', type: 'home'})
		this.setState({numbers}, () => document.getElementById(`${newIndex}-phone-number`).focus())
	}

	addEmailField = () => {
		let emails		= this.state.emails
		let newIndex	= emails.length
		emails.push('')
		this.setState({emails}, () => document.getElementById(`${newIndex}-email`).focus())
	}

	removePhoneNumber = index => {
		let numbers = this.state.numbers
		numbers.splice(index, 1)
		this.setState({numbers})
	}

	removeEmail = index => {
		let emails = this.state.emails
		emails.splice(index, 1)
		this.setState({emails})
	}

	expandBasicInfo			= () => this.setState({basicInfoHidden: false})
	expandHomeAddress		= () => this.setState({homeHidden: false})
	expandBusinessAddress	= () => this.setState({businessHidden: false})

	renderBasicInfoSection = () => {
		const { classes }	= this.props
		let isHidden		= this.state.basicInfoHidden
		return (
			<div className={`${classes.dataSection} ${isHidden ? 'collapsed' : ''}`}>
				<DefaultArrowTooltip
					title		= {isHidden ? 'Expand' : 'Collapse'}
					placement	= 'right'
				>
					<span
						className='label first-label clickable'
						onClick={() => {
							if (isHidden) document.getElementById('0-first-name').focus()
							this.setState({basicInfoHidden: !isHidden})
						}}
					>
						Basic Info {isHidden ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
					</span>
				</DefaultArrowTooltip>
				<div className={classes.twoInputsWrapper}>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'First Name', true, this.state.name.first, false, e => this.onNameChange(e, 'first'), this.expandBasicInfo)}
					</div>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'Last Name', true, this.state.name.last, false, e => this.onNameChange(e, 'last'), this.expandBasicInfo)}
					</div>
				</div>
				<div className={classes.twoInputsWrapper}>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'Middle Name', true, this.state.name.middle, false, e => this.onNameChange(e, 'middle'), this.expandBasicInfo)}
					</div>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'Nickname', true, this.state.name.nick, false, e => this.onNameChange(e, 'nick'), this.expandBasicInfo)}
					</div>
				</div>
				<div className={classes.twoInputsWrapper}>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'Company', true, this.state.organization, false, this.onOrganizationChange, this.expandBasicInfo)}
					</div>
					<div className={classes.inputWrapper}>
						{this.renderInput(0, false, 'Job', true, this.state.job_title, false, this.onJobTitleChange, this.expandBasicInfo)}
					</div>
				</div>
				<div style={{marginTop: 8}}>
					{this.renderInput(0, true, 'Notes', true, this.state.notes, false, e => this.setState({notes: e.target.value}), () => {})}
				</div>
			</div>
		)
	}

	renderPhoneNumbersSection = () => {
		const { classes } = this.props
		let numberTypes = this.state.numberTypes.map((value, key) => ({key, value}))
		return (
			<div className={classes.dataSection}>
				<span className='label first-label'><span className='highlight'>Phone Number</span></span>
				<span className='label second-label'><span className='highlight'>Phone Number Type</span></span>
				{this.state.numbers.map((n, i) => {
					let numberType = numberTypes.find(nt => nt.value === n.type)
					return (
						<div key={i} className={classes.phoneNumberSection}>
							{this.renderInput(i, false, 'Phone Number', false, this.formatNumberAsYouType(n.number), n.fixed, e => this.onNumberChange(e, i), () => {})}
							{this.renderInput(i, false, 'Phone Number Type', false, numberType, false, (e, v) => this.onNumberTypeChange(v, i), () => {}, numberTypes)}
							<DefaultArrowTooltip
								title		= {!n.fixed ? 'Remove' : ''}
								placement	= 'left'
							>
								<div
									onMouseEnter	= {() => this.setState({hoverRemovePhone: true})}
									onMouseLeave	= {() => this.setState({hoverRemovePhone: false})}
									onClick			= {() => !n.fixed ? this.removePhoneNumber(i) : null}
								>
									<RemoveCircleIcon
										classes={{root: `${classes.removeIcon} ${this.state.hoverRemovePhone ? 'hover' : ''} ${n.fixed ? 'not-allowed' : ''}`}}
									/>
								</div>
							</DefaultArrowTooltip>
						</div>
					)
				})}
				{this.state.numbers.length < 5 ?
					<div
						className		= {classes.addButton}
						onMouseEnter	= {() => this.setState({hoverAddPhone: true})}
						onMouseLeave	= {() => this.setState({hoverAddPhone: false})}
						onClick			= {this.addPhoneNumberField}
					>
						<PlusCircleIcon className={classes.addIcon} hover={this.state.hoverAddPhone ? 1 : 0}/>
						Add Phone Number
					</div>
				: null}
			</div>
		)
	}

	renderEmailsSection = () => {
		const { classes } = this.props
		return (
			<div className={classes.dataSection}>
				<span className='label first-label'><span className='highlight'>Email</span></span>
				{this.state.emails.map((email, i) => (
					<div key={i} className={classes.emailWrapper}>
						{this.renderInput(i, false, 'Email', false, email, false, e => this.onEmailChange(e, i), () => {})}
						<DefaultArrowTooltip
							title		= 'Remove'
							placement	= 'left'
						>
							<div
								onMouseEnter	= {() => this.setState({hoverRemoveEmail: true})}
								onMouseLeave	= {() => this.setState({hoverRemoveEmail: false})}
								onClick			= {() => this.removeEmail(i)}
							>
								<RemoveCircleIcon
									classes={{root: `${classes.removeIcon} ${this.state.hoverRemoveEmail ? 'hover' : ''}`}}
								/>
							</div>
						</DefaultArrowTooltip>
					</div>
				))}
				{this.state.emails.length < 2 ?
					<div
						className		= {classes.addButton}
						onMouseEnter	= {() => this.setState({hoverAddEmail: true})}
						onMouseLeave	= {() => this.setState({hoverAddEmail: false})}
						onClick			= {this.addEmailField}
					>
						<PlusCircleIcon className={classes.addIcon} hover={this.state.hoverAddEmail ? 1 : 0}/>
						Add Email
					</div>
				: null}
			</div>
		)
	}

	renderAddressInfo = addressType => {
		const { classes }	= this.props
		let isHidden		= this.state[`${addressType}Hidden`]
		let id				= addressType === 'home' ? 0 : 1
		let addressTypeC	= `${addressType[0].toUpperCase()}${addressType.substring(1)}`
		return (
			<div className={`${classes.dataSection} ${isHidden ? 'collapsed' : ''}`}>
				<DefaultArrowTooltip
					title		= {isHidden ? 'Expand' : 'Collapse'}
					placement	= 'right'
				>
					<span
						className='label first-label clickable'
						onClick={() => {
							if (isHidden) document.getElementById(`${id}-address`).focus()
							this.setState({[`${addressType}Hidden`]: !isHidden})
						}}
					>
						{`${addressType[0].toUpperCase()}${addressType.substring(1)}`} {isHidden ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
					</span>
				</DefaultArrowTooltip>
				<div className={classes.inputWrapper}>
					{this.renderInput(id, false, 'Address', true, this.state.addresses[addressType].address, false, e => this.onAddressChange(e, addressType), this[`expand${addressTypeC}Address`])}
				</div>
				<div className={classes.inputWrapper}>
					{this.renderInput(id, false, 'City', true, this.state.addresses[addressType].city, false, e => this.onCityChange(e, addressType), this[`expand${addressTypeC}Address`])}
				</div>
				<div className={classes.inputWrapper}>
					{this.renderInput(id, false, 'State', true, this.state.addresses[addressType].state, false, e => this.onStateChange(e, addressType), this[`expand${addressTypeC}Address`])}
				</div>
				<div className={classes.inputWrapper}>
					{this.renderInput(id, false, 'Country', true, this.state.addresses[addressType].country, false, e => this.onCountryChange(e, addressType), this[`expand${addressTypeC}Address`])}
				</div>
				<div className={classes.inputWrapper}>
					{this.renderInput(id, false, 'Zip Code', true, this.state.addresses[addressType].zip_code, false, e => this.onZipCodeChange(e, addressType), this[`expand${addressTypeC}Address`])}
				</div>
			</div>
		)
	}

	onCancel = () => this.props.onClose()

	isSaveEnabled = () => {
		let contact = this.props.contact
		let stateName = this.state.name
		if (!stateName.display ||  (stateName.display && stateName.display.trim() ==='')) return false
		if (!contact) return true

		let checkNameChange = () => {
			let stateName = this.state.name
			let contactName = contact.name
			return (stateName.display !== contactName.display ||
				stateName.first !== contactName.first ||
				stateName.last !== contactName.last ||
				stateName.middle !== contactName.middle ||
				stateName.nick !== contactName.nick)
		}

		let checkNumberChange = () => {
			let stateNumbers = this.state.numbers
			let contactNumbers = contact.numbers.filter(n => n.number)
			if (stateNumbers.length !== contactNumbers.length) return true
			let hasChange = false
			stateNumbers.forEach((sn, sni) => {
				if (hasChange) return
				let foundNumber = contactNumbers.find((cn, cni) => cn.number === sn.number && cn.type === sn.type && cni === sni)
				if (!foundNumber) hasChange = true
			})
			return hasChange
		}

		let checkEmailChange = () => {
			let stateEmails = this.state.emails
			let contactEmails = contact.emails
			if (stateEmails.length !== contactEmails.length) return true
			let hasChange = false
			stateEmails.forEach((se, sei) => {
				if (hasChange) return
				let foundEmail = contactEmails.find((ce, cei) => ce === se && cei === sei)
				if (!foundEmail) hasChange = true
			})
			return hasChange
		}

		let checkAddressChange = addressType => {
			let stateAddress = this.state.addresses[addressType]
			let contactAddress = contact.addresses[addressType]
			return stateAddress.address !== contactAddress.address ||
				stateAddress.city !== contactAddress.city ||
				stateAddress.state !== contactAddress.state ||
				stateAddress.country !== contactAddress.country ||
				stateAddress.zip_code !== contactAddress.zip_code
		}

		let checkJobChange = () => {
			return this.state.organization !== contact.organization ||
				this.state.job_title !== contact.job_title
		}
		
		let checkNoteChange = () => this.state.notes !== contact.notes

		let checkGroupChange = () => {
			let stateGroup = this.state.group
			return (!stateGroup && contact.group && contact.group.id) ||
				(stateGroup && stateGroup.id !== contact.group.id) ||
				(stateGroup && stateGroup.name !== contact.group.name)
		}

		let hasNameChange				= checkNameChange()
		let hasNumberChange				= checkNumberChange()
		let hasEmailChange				= checkEmailChange()
		let hasHomeAddressChange		= checkAddressChange('home')
		let hasBusinessAddressChange	= checkAddressChange('business')
		let hasJobChange				= checkJobChange()
		let hasNoteChange				= checkNoteChange()
		let hasGroupChange				= checkGroupChange()
		return hasNameChange || hasNumberChange || hasEmailChange || hasHomeAddressChange || 
			hasBusinessAddressChange || hasJobChange || hasNoteChange || hasGroupChange
	}

	onSave = async () => {
		this.setState({loading: true})
		let name			= this.state.name
		let numbers			= this.state.numbers.map(n => {
			let phoneNumber = formatPhoneNumber(n.number)
			return {
				number:	phoneNumber,
				type:	n.type
			}
		})
		let emails			= this.state.emails
		let homeAddress		= this.state.addresses.home
		let businessAddress	= this.state.addresses.business
		let organization	= this.state.organization
		let job_title		= this.state.job_title
		let notes			= this.state.notes
		let data = {
			name,
			organization,
			job_title,
			emails,
			notes,
			numbers,
			addresses: {
				home: homeAddress,
				business: businessAddress
			}
		}

		let group = this.state.group
		if (!group.id && !group.name) group = null
		else if (!group.id) delete group.id
		if (group) data.group = group

		let contactId = this.props.contact ? this.props.contact.id : null
		if (contactId) {
			data.contact_id = contactId
			await Api.updateContact(data)
		} else {
			contactId = (await Api.createContact(data)).contact_id
		}

		let contactData = {
			id:					contactId,
			name:				this.state.name,
			addresses:			this.state.addresses,
			emails:				this.state.emails,
			numbers:			this.state.numbers,
			group:				this.state.group,
			organization:		this.state.organization,
			job_title:			this.state.job_title,
			notes:				this.state.notes
		}
		this.props.saveContact(contactData)
		this.props.onClose()
	}

	onDelete = async () => {
		this.setState({showDeleteModal: false, loading: true})
		let contactId = this.props.contact.id
		let response = await Api.deleteContact(contactId)
		this.setState({loading: false})
		this.props.deleteContact(contactId)
		this.props.onClose()
	}

	renderFooter = () => {
		const { classes } = this.props
		return (
			<div className={classes.footer}>
				<div>
					{this.props.type === 'Edit' ?
						<PDCButton
							variant	= 'flat'
							onClick	= {() => this.setState({showDeleteModal: true})}
						>Delete Contact</PDCButton>
					: null}
				</div>
				<div className={classes.buttonsGroup}>
					<PDCButton variant='flat' onClick={this.onCancel}>Cancel</PDCButton>
					<PDCButton variant='raised' disabled={!this.isSaveEnabled()} onClick={this.onSave}>Save</PDCButton>
				</div>
			</div>
		)
	}

	renderConfirmModal = () => {
		return (
			<ConfirmModal
				title			= 'Are you sure you want to delete this contact?'
				isShown			= {this.state.showDeleteModal}
				content			= {null}
				noButtonText	= 'Cancel'
				yesButtonText	= 'Confirm'
				onReject		= {() => this.setState({showDeleteModal: false})}
				onConfirm		= {this.onDelete}
				size			= 'size2'
			/>
		)
	}

	addType = () => {
		let stateTypesName = this.state.showAddTypeModal === 'number' ? 'numberTypes' : 'contactGroupTypes'
		let newType = this.state.newType
		if (this.state.showAddTypeModal === 'group') {
			newType = {
				id:		null,
				name:	newType
			}
		}
		let types = this.state[stateTypesName]
		types.splice(types.length - 1, 0, newType)
		this.setState({[stateTypesName]: types, newType: '', showAddTypeModal: false})
	}

	onNewTypeChange = e => {
		let newType = e.target.value
		if (newType.length > 30) return
		this.setState({newType})
	}

	renderAddTypeModal = () => {
		const { classes } = this.props
		let textLabel = this.state.showAddTypeModal === 'group' ? 'Group Type' : 'Phone Number Type'
		return (
			<Dialog
				open	= {Boolean(this.state.showAddTypeModal)}
				classes	= {{paper: classes.addDialog}}
			>
				<DialogContent classes={{root: classes.addDialogContent}}>
					<TextField
						id			= 'new-type'
						label		= {textLabel}
						value		= {this.state.newType}
						onChange	= {this.onNewTypeChange}
						autoFocus
					/>
				</DialogContent>
				<DialogActions classes={{root: classes.addDialogFooter}}>
					<PDCButton
						className	= {classes.rejectButton}
						onClick		= {() => this.setState({showAddTypeModal: false, newType: ''})}
						color		= 'tertiary'
						variant		= 'flat'
					>
						Cancel
					</PDCButton>
					<PDCButton
						className	= {classes.confirmButton}
						onClick		= {this.addType}
						color		= 'primary'
						variant		= 'raised'
						disabled	= {!Boolean(this.state.newType)}
					>
						Confirm
					</PDCButton>
				</DialogActions>
			</Dialog>
		)
	}

	render() {
		const { classes }		= this.props
		let contactGroupTypes	= this.state.contactGroupTypes.map(gt => ({
			key:	gt.id,
			value:	gt.name
		}))
		let group = this.state.group ? {
			key:	this.state.group.id,
			value:	this.state.group.name
		} : {key: null, value: ''}
		return (
			<Dialog
				open	= {Boolean(this.props.type)}
				classes	= {{root: classes.dialogRoot, paper: `${classes.dialog} ${this.props.size || ''}`}}
			>
				<DialogContent classes={{root: classes.dialogContent}}>
					<>
						{this.state.loading ? <div className={classes.loadingDiv}><LoaderFull/></div> : null}
						<div className={classes.header}>
							<AddContactIcon className={classes.contactIcon}/>
							<span>{this.props.type} contact</span>
						</div>
						<div className={classes.mainSection}>
							<div className={classes.dataSection}>
								<div className={classes.inputWrapper}>
									{this.renderInput(0, false, 'Contact Name', true, this.state.name.display, false, e => this.onNameChange(e, 'display'), () => {}, null, true)}
								</div>
								<div className={classes.inputWrapper}>
									{this.renderInput(0, false, 'Group', true, group, false, (e, v) => this.onGroupChange(v), () => {}, contactGroupTypes)}
								</div>
							</div>
							{this.renderBasicInfoSection()}
							{this.renderPhoneNumbersSection()}
							{this.renderEmailsSection()}
							{this.renderAddressInfo('home')}
							{this.renderAddressInfo('business')}
						</div>
						{this.renderFooter()}
						{this.renderConfirmModal()}
						{this.renderAddTypeModal()}
					</>
				</DialogContent>
			</Dialog>
		)
	}
}

export default withStyles(styles)(EditContactModal)