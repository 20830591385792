import React, {useState} from 'react';
import './ExtSel.css';
import { FSPrivate } from 'privacy';
import searchIcon from '../images/icon-search.svg'

const style = {
    extensionNum: {width: '45%', paddingRight: '10px', display: 'block', float:'left', fontWeight: 'bold'},
    extensionName: {width: '55%', display: 'block', overflow: 'auto', verticalAlign: 'middle', wordBreak: 'break-word'},
    extensionList: {width: '100%', maxHeight: '310px', float: 'left', direction: 'rtl', paddingLeft: '15px', display: 'flex'}
}

class InlineExtensionSelector extends React.Component {

    constructor(props) {
        super(props);

        let extensionData = props.extensionData || [];
        this.state = {
            extensions: extensionData,
        }

        this.buttonStyle = {
            textTransform: 'none',
            backgroundColor: 'purple',
            padding: '5px',
            boxShadow: 'none',
            width: '95%'
        }

        this.isMobileView = props.screenViewType.isMobileView
        this.isTabletView = props.screenViewType.isTabletView
    }

    extensionSelectHandler = (item, index) => {
        if (this.props.onExtensionSelect) {
            this.props.onExtensionSelect(item, index);
        }
    }

    render() {
        return (
            <div style={{height: (this.isMobileView || this.isTabletView) ? 'fit-content' : '100%'}}>
                {this.state.extensions.length > 5 ? this.renderFilterItem() : null}
                <table className='extension-menu-list' style={style.extensionList}>
                    <tbody style={{width: '100%'}}>
                        {this.state.extensions.map((item, i) => {
                            return (<ExtensionItem
                                        key={item.extension}
                                        selected={i === 0}
                                        item={item}
                                        index={i}
                                        onClick={() => this.extensionSelectHandler(item, i)}
                                        smallScreen={this.isMobileView || this.isTabletView}
                                    />)
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderFilterItem() {
        return (
            <div className='filter-item' style={{marginBottom: '5px'}}>
                <FSPrivate>
                <input type="text" placeholder='Search extensions' onChange={this.onFilterChange} />
                </FSPrivate>
                <img src={searchIcon} className='search-icon' alt='search icon' />
            </div>
        );
    }

    onFilterChange(e) {
        e.persist();
        let inputElement = e.target
        let filterValue = inputElement.value;

        // Questionable DOM manipulation
        Array.from(inputElement.parentElement.parentElement.children[1].children[0].children).forEach(element => {
            if (element.classList.contains('extension-item')) {
                if (element.innerText.toLowerCase().includes(filterValue.toLowerCase())) {
                    element.classList.remove('hidden');
                } else {
                    element.classList.add('hidden');
                }
            }
        });
    }
}

const ExtensionItem = props => {
    let item = props.item;
    const [hover, setHover] = useState(false);

    return (
        <FSPrivate>
        <tr className='extension-item' style={{width: '100%', overflow: 'auto', display: 'block', background: hover ? 'lightgray' : null, lineHeight: props.smallScreen ? 2 : 1.5}} 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={props.onClick}
        >
            <td style={style.extensionNum}>{`EXT. ${item.extension}`}</td>
            <td style={style.extensionName}>{`${item.extension_name}`}</td>
        </tr>
        </FSPrivate>
    )
}

export default InlineExtensionSelector;