import React, { Component } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'
import { FSPrivate } from 'privacy'

const styles = theme => ({
	phoneNumberSection: {
		color:			theme.palette.primary.main,
		fontSize:		14,
		fontWeight:		600,
		paddingBottom:	5,
		paddingTop:		5,
		paddingLeft:	15,
		marginLeft:		-15,
		lineHeight:		2.14,
		'&:hover': {
			cursor:		'pointer',
			background:	'rgba(0, 0, 0, 0.05)'
		},
		'& .number-type': {
			color:			theme.palette.tertiary.main,
			fontWeight:		'normal',
			display:		'inline-block',
			minWidth:		60,
			marginRight:	20
		}
	},
	contactWrapper:{
		padding: '0 50px 20px',
		'&.small-view': {
			padding: '0 30px 10px'
		}
	},
	contactName: {
		fontSize:		16,
		fontWeight:		600,
		letterSpacing:	-0.1,
		lineHeight:		1.31,
		marginBottom:	3
	},
	noContactsResult: {
		color:		'#dc0000',
		fontStyle:	'italic',
		fontSize:	'14px'
	},
	ContactSelectorBody:{
		height:		'100%',
		minHeight:	0,
		marginTop:	20
	}
})
class ContactSelector extends Component {

	render() {
		const { classes } = this.props
		let searchQuery = this.props.searchQuery
		return (
				<div className={classes.ContactSelectorBody}>
					<InfiniteScroller
						loadMore={this.props.loadMore}
						hasMore={this.props.hasMore}
						loader={<Spinner/>}
					>
						{this.props.contacts.map((contact, i) => {
							if (contact === 'no-results') {
								if (this.props.isLoading) return null
								return <div key={`searched-contacts-no-results`} className={classes.noContactsResult}>No contacts match your query</div>
							}
							return (
								<div key={`searched-contacts-${i}`} className={`${classes.contactWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
									<FSPrivate>
										<div className={classes.contactName}>
											{contact.name.display}
										</div>
										<div>
											{contact.numbers.filter(n => {
												let numberFound	= n.number.includes(searchQuery)
												let nameFound	= contact.name.display.toLowerCase().includes(searchQuery.toLowerCase())
												return n.number && (!searchQuery || numberFound || nameFound)
											}).map((n, i2) => {
												let number = n.number.split('-').join('')
												let type = n.type
												return (
													<div
														key			= {`number-${i}-${i2}`}
														onClick		= {() => this.props.selectContact(number, contact.name.display)}
														className	= {classes.phoneNumberSection}
													>
														<span className='number-type'>{type[0].toUpperCase()}{type.substring(1)}</span>
														<span className='number'>{formatPhoneNumber(number)}</span>
													</div>
												)
											})}
										</div>
									</FSPrivate>
								</div>
							)
						})}
					</InfiniteScroller>
				</div>
		)
	}
}

export default withStyles(styles)(ContactSelector)