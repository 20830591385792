/* react-linkify but I modified the decorators */
import React, { Component } from 'react'
import {NetworkInterceptor} from "network-interceptor";
import {getAppConfig} from "phonecom";

class LoginRedirector extends Component {
constructor(props) {
    super(props);
    this.state = {
        forbidden: false
    }
}
   componentDidMount() {
        NetworkInterceptor((resp)=>{
            if(resp.responseURL.includes('phone.com') && (resp.status === 403 || resp.response === '{"error": {"message": "Forbidden", "http_code": 403, "code": 403}}')){
                this.setState({forbidden:true});
                console.log('failed auth');
                console.log('redirect');
                getAppConfig().then(()=>{
                    window.location.href = `${window.APP_CONFIG.redirect_url}${window.location.href}`
                })
            }
        });
   }

    render() {
        return this.props.children;
    }
}

export default LoginRedirector