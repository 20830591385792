import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    pdcButton: {
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 34.5,
        borderRadius: 25,
        border: `solid 1.5px`,
        backgroundColor: 'white',
        fontSize: 16,
        fontWeight:'bold',
        lineHeight: 1.31,
        letterSpacing: -0.1,
        position:'relative',
        textAlign: 'center',
        padding: '5px 20px 5px 20px',
        '&:focus':{
            outline: 'none'
        }

    },
    primaryButton: {
        boxShadow: '0 0 0 0 #238a12',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.main,
            color: 'white'
        }
    },

    tertiaryButton:{
        boxShadow: `0 0 0 0 ${theme.palette.tertiary.dark}`,

        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.tertiary.main,
            color:'white'
        }
    },
    primaryOutlinedButton:{
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            borderColor: theme.palette.tertiary.main,

            color:'white'
        }
    },
    tertiaryOutlinedButton:{
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: 'white'
        }
    },
    flatButton:{
        boxShadow: `0 0 0 0`,
        backgroundColor:'transparent',
        border:'solid 0px'
    },
    primaryFlatButton:{
        backgroundColor:'transparent',
        '&:hover': {
            color: theme.palette.tertiary.main,
            backgroundColor:'transparent',

        }
    },
    tertiaryFlatButton:{
        backgroundColor:'transparent',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor:'transparent',

        }
    },
    primaryRaisedButton:{
        boxShadow: `-2.5px 2.5px 0 0 ${theme.palette.primary.dark}`,

    },
    tertiaryRaisedButton:{
        boxShadow: `-2.5px 2.5px 0 0 ${theme.palette.tertiary.dark}`,
    },

    standardButton:{
        boxShadow: `0 0 0 0`,
        border:'solid 0px',
        color:  'white'
    },
    primaryStandardButton:{
        color:  'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        }
    },
    disabled:{
        backgroundColor: theme.palette.tertiary.xxlite,
        borderColor: theme.palette.tertiary.xxlite,
        color:'white'
    }

})

/**
 * This component is the My Phone.com styled button, given the color and variant it will return the properly
 * styled button.
 *
 * This component supports all props the normal <button/> component can.
 *
 * Example Uses:
 *  <PDCButton variant='raised' color='tertiary' onClick={()=>{alert('hi')}}>
 *      Click here for Alert
 *   </PDCButton>
 *
 *    <PDCButton variant='outlined' color='primary' onClick={()=>{alert('hi')} disabled={true}>
 *      Not Enabled
 *   </PDCButton>
 *
 * Defaults to variant='raised' color='primary'
 *
 * @color [primary (green) | tertiary (grey)] the color color palette the button should use
 * @variant [raised | outlined | flat] The style of the button
 */
class PDCButton extends Component {

    getButtonClasses(color, variant){
        let {classes} =  this.props

        let buttonColorClass = ''
        if (color === 'primary') {
            buttonColorClass = classes.primaryButton
        } else if (color === 'tertiary') {
            buttonColorClass = classes.tertiaryButton
        }

        if (variant === 'raised') {
            buttonColorClass += ` ${classes[color + 'RaisedButton']}`
        } else if (variant === 'outlined') {
            buttonColorClass += ` ${classes[color + 'OutlinedButton']}`
        }else if(variant === 'flat'){
            buttonColorClass += ` ${classes[color + 'FlatButton']} + ${classes.flatButton} `
        }else if(variant === 'standard'){
            buttonColorClass += ` ${classes[color + 'StandardButton']} + ${classes.primaryButton} `
        }
        return buttonColorClass
    }

    render() {
        let variant = this.props.variant || 'raised'
        let color = this.props.color || 'primary'

        let {classes} =  this.props
        let buttonColorClass
        if(this.props.disabled || this.props.variant=== 'disabled') {
            buttonColorClass = classes.disabled
        }else {
            buttonColorClass = this.getButtonClasses(color, variant)
        }



        return (
            <button {...this.props}  className={`${classes.pdcButton} ${buttonColorClass}  ${this.props.className}`}>
                {this.props.children}
            </button>
        )
    }
}

export default withStyles(styles)(PDCButton)