import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

import withTheme from '@material-ui/core/styles/withTheme'

const store = createStore(rootReducer)

class Calls extends React.Component {

	render() {
		return (
			<Provider store={store}>
				<App
					extension					= {this.props.extension}
					contactsUtil				= {this.props.contactsUtil}
					screenViewType				= {this.props.screenViewType}
					standalone					= {this.props.standalone}
					onLoaded					= {this.props.onLoaded}
					subscribeForNotifications	= {this.props.subscribeForNotifications}
					resetSubscription			= {this.props.resetSubscription}
					updateUnreadCounts			= {this.props.updateUnreadCounts}
					changeVoicemailReadStatus	= {this.props.changeVoicemailReadStatus}
					redirect					= {this.props.redirect}
				/>
			</Provider>
		)
	}
}

export default withTheme(Calls)