import Notifier from 'react-desktop-notification'
import notificationSound from './sounds/notifcation-sound.mp3'
import { theme } from 'get-theme'

const audio = new Audio(notificationSound);

const pushNotification = (title = null, body = null, url= null, icon = null, windowName = null) => {

    if (!window.Notification || !window.Notification.requestPermission) {
        return false;
	}

    title = title ||  'My Phone.com';
    body = body ||  'new notification';
	icon = icon || `/icons/${theme.notificationIconName}`
    url = url ||  'https://my.phone.com';
    windowName =  window.name || 'My Phone.com';
    window.name = windowName;


    if (window.V5PHONECOM.features.has('web_notification_sound')) {
        audio.play().then(() => {
        }).catch((e => {
            console.log('notification audio failed to played')
        }))
    }

    return Notifier.focus(title, body, url, icon)
};


const pushVoicemailNotification = (voicemail = null, extensionId = null) => {
	let voicemailId	= voicemail.id
	let url			= `https://${window.document.location.host}/e${extensionId}/voicemail/v${voicemailId}`
	let name		= 'n/a'
	if (voicemail.from) {
		name		= voicemail.from.name || voicemail.from.number
	}
	let title		= `New voicemail from: ${name}`
	pushNotification(title, voicemail.transcript || ' ', url, null, 'My Phone.com')
}

const pushFaxNotification = (fax=null, extensionId=null) => {
	let faxId	= fax.id
	let url		= `https://${window.document.location.host}/e${extensionId}/faxes/f${faxId}`
	let name	= 'n/a'
	if (fax.from) {
		name		= fax.from.name || fax.from.number
	}
	let title	= `New fax from: ${name}`
	pushNotification(title, `${fax.pages} pages`, url, null, 'My Phone.com')
}

const pushMessageNotification = (message = null, extensionId = null) => {

    if (message.direction === 'in') {
        // Re do when we add web push
        let conv_id = message.conversation_id;
        let url = `https://${window.document.location.hostname}/e${extensionId}/c${conv_id}`;
        let title = 'New notification from: ' + message.from;
        pushNotification(title, message.text, url, null, 'My Phone.com');
    }

};


export {pushNotification, pushVoicemailNotification, pushFaxNotification, pushMessageNotification}