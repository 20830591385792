import React, { Component } from 'react';

import { connect } from 'react-redux';
import { switchView } from '../../actions/view.js';
import { switchConversation, addConversation, deleteConversation } from '../../actions/conversations.js';

import Api from 'api'

import PhoneComUser from 'phone-com-user'

import {
	isValidNumber as isValidNumberCustom,
	getNumberType as getNumberTypeCustom,
	format as formatCustom,
	parse as parseCustom,
} from 'libphonenumber-js/custom';

import metadata from 'libphonenumber-js/metadata.full.json';

import StartNewPanel from 'start-new-panel'

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata);
export const AsYouType = (...args) => getNumberTypeCustom(...args, metadata);
export const format = (...args) => formatCustom(...args, metadata);
export const parse = (...args) => parseCustom(...args, metadata);

const mapStateToProps = state => ({conversations: state.conversations})
const mapDispatchToProps = dispatch => {
	return {
		switchConversation:	conv => dispatch(switchConversation(conv)),
		addConversation:	conv => dispatch(addConversation(conv)),
		deleteConversation:	conv => dispatch(deleteConversation(conv)),
		switchView:			view => dispatch(switchView(view)),
	}
}

class NewConversationPanel extends Component {

	componentDidMount() {
		document.getElementById('tags-input').focus()
	}

	startConversation = (selectedNumber, recipients) => {

		// Find the conversation from props.conversations
		// In shownConversation will be kept the conversation if it is shown in the ConversationSelector
		let shownConversation;
		this.props.conversations.forEach(conversation => {

			// If it is found, do not search for it
			if (shownConversation) {
				return;
			}

			let conversationParticipantNumbers = conversation.participants.map(p => p.number);
			let newParticipantsNumbers = Array.from(new Set(recipients.map(r => r.number).concat([selectedNumber])));

			if (conversationParticipantNumbers.length !== newParticipantsNumbers.length) {
				return;
			}

			shownConversation = conversation;
			newParticipantsNumbers.forEach(phoneNumber => {
				if (!conversationParticipantNumbers.includes(phoneNumber)) {
					shownConversation = null;
				}
			});
		});

		if (!shownConversation) {
			// If it is not found in the shown conversations then:
			// Add conversation in props (it will add it to the ConversationSelector) WITHOUT conversation_id
			let otherRecipients = this._removeMyPhoneNumbersFromRecipients(recipients);
			let myPhoneNumbers = selectedNumber ? [{number: selectedNumber}] : []
			let recipientsPhoneNumbers = this._extractMyPhoneNumbersFromRecipients(recipients) || []
			recipientsPhoneNumbers = recipientsPhoneNumbers.filter(n => n !== selectedNumber).map(n => {return {number: n}})
			myPhoneNumbers = myPhoneNumbers.concat(recipientsPhoneNumbers)

			let newConversation = {
				checkedMessages: true,
				from: myPhoneNumbers,
				my_nums: myPhoneNumbers,
				id: null, // Will be added later
				participants: otherRecipients.concat(myPhoneNumbers),
				to: otherRecipients,
				not_my_nums: otherRecipients,
				newlyAdded: true,
				last_message: {}
			}
			this.props.addConversation(newConversation)
			this.props.loadExtraContacts()
			shownConversation = newConversation
		}

		this.props.switchView('content')
		// When new conversation is created it doesn't know the conversation id immediately so firstly it will be set to null in the path
		// and once the conversation id is loaded ConversationContent will add it
		this.props.switchConversation(shownConversation)
	}

	_extractMyPhoneNumbersFromRecipients = recipients => {
		let numbers = recipients.map(r => r.number);
		let allMyPhoneNumbers = PhoneComUser.getPhoneNumber();
		return allMyPhoneNumbers ? allMyPhoneNumbers.filter(n => numbers.includes(n)) : [];
	}

	_removeMyPhoneNumbersFromRecipients = recipients => {
		let allMyPhoneNumbers = PhoneComUser.getPhoneNumber();
		let otherRecipients = recipients.filter(r => !allMyPhoneNumbers.includes(r.number));
		return otherRecipients.map(r => {
			return {name: r.nickname, number: r.number};
		});
	}

	onStartClick = (selectedNumber, recipients) => {
		let newlyAddedConversation = this.props.conversations.find(c => c.newlyAdded)
		if (newlyAddedConversation) {
			this.props.deleteConversation(newlyAddedConversation)
		}
		this.startConversation(selectedNumber, recipients)
	}

	closeNewConversationPanel = () => {
		this.props.switchView('select')
	}

	render() {
		let phoneNumbers	= PhoneComUser.getPhoneNumber()
		let inputNotAllowed	= Boolean(phoneNumbers === undefined || phoneNumbers.length === 0)
		return (
			<StartNewPanel
				extension		= {this.props.extension}
				contactsUtil	= {this.props.contactsUtil}
				showSendToField	= {true}
				allowShortCodes	= {true}
				inputNotAllowed	= {inputNotAllowed}
				goBackText		= 'New Chat'

				onStartClick	= {this.onStartClick}
				goBack			= {this.closeNewConversationPanel}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewConversationPanel)