import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectorItem from 'selector-item'
import { VoicemailIcon } from 'pdc-svg-icons'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { updateCall, switchCall } from '../actions/calls'
import ConfirmDeleteModal from 'confirm-delete-modal'

import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'

const mapStateToProps = state => ({
	calls:			state.calls,
	currentCall:	state.currentCall
})

const mapDispatchToProps = dispatch => ({
	updateCall: call => dispatch(updateCall(call)),
	switchCall: call => dispatch(switchCall(call))
})

const styles = theme => ({
	beforeMainText: {
		marginRight: '7px',
		'&>svg': {
			width:	'20px',
			height:	'20px',
			'&:not(:first-child):last-child': {
				marginLeft: 5
			}
		}
	},
	recIcon: {
		color:	'#88969E'
	}
})

class CallselectorItem extends Component {

	state = {showDeleteCallDialog: false}

	renderRecordingIcon = () => {
		const { classes, call } = this.props
		let recordingUrl = call.recording.url
		let voicemailUrl = call.voicemail.url
		if (!recordingUrl && !voicemailUrl) return
		return <span className={classes.beforeMainText}>
			{recordingUrl ? <RecordVoiceOverIcon classes={{root: classes.recIcon}}/> : null}
			{voicemailUrl ? <VoicemailIcon/> : null}
		</span>
	}

	getParticipants = () => {
		let call	= this.props.call
		let number	= call.type === 'incoming' ? call.from.number : call.to.number
		let name	= ''
		this.props.extraContacts.forEach(cd => {
			let foundNumber = cd.numbers.find(n => n.number === number)
			if (!foundNumber) return
			name = cd.name.display
		})
		return [{number, name}]
	}

	render() {
		let { call } = this.props
		let styles = {
			mainText: {
				textTransform:	'uppercase',
				fontWeight:		'bold',
				color:			theme.palette.primary.main
			}
		}
		return (
			<div>
				<SelectorItem
					mainText			= {call.type.charAt(0).toUpperCase() + call.type.slice(1)}
					beforeMainText		= {this.renderRecordingIcon()}
					participants		= {this.getParticipants()}
					timestamp			= {call.start_time}
					showMyNums			= {false}
					active				= {this.props.active}
					isNew				= {false}
					bold				= {false}
					onClick				= {this.props.onClick}
					styles				= {styles}
					renderDropdownMenu	= {null}
				/>
				<ConfirmDeleteModal
					itemType					= 'call'
					isShown						= {this.state.showDeleteCallDialog}
					acknowledgedTitle			= 'Permanently delete call'
					notAcknowledgedTitle		= 'Permanently delete call'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
					onClose 					= {this.hideDialog}
					onDelete					= {this.deleteAndSwitchCall}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallselectorItem))