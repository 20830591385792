import React, { Component } from 'react'

import HeaderBar from 'header-bar'
import ConfirmDeleteModal from 'confirm-delete-modal'
import VoicemailHeaderDropdownMenu from './VoicemailHeaderDropdownMenu'

import { FlagIcon } from 'pdc-svg-icons'

import { formatPhoneNumber } from 'phone-numbers'
import { DefaultTooltip, DefaultArrowTooltip } from 'tooltips'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	readStatusButton:	theme.readStatusButton,
	headerItem:			theme.vmFaxHeaderItem,
	participant: {
		cursor: 'pointer'
	},
	actions: {
		display: 'flex'
	}
})

class VoicemailHeader extends Component {

	state = {
		showDeleteVoicemailDialog:	false,
		unreadIconHovered:			false
	}

	onDeleteClick = () => this.setState({showDeleteVoicemailDialog: true})

	hideDialog = () => this.setState({showDeleteVoicemailDialog: false})

	deleteAndSwitchVoicemail = () => {
		this.props.deleteVoicemail()
		this.hideDialog()
	}

	setUnreadIconHovered = unreadIconHovered => this.setState({unreadIconHovered})

	renderParticipant = () => {
		let { classes }		= this.props
		let voicemail		= this.props.voicemail
		let tooltipText		= `Mark ${voicemail.is_new ? '' : 'un'}read`
		let name			= voicemail.from.name
		let contactId		= voicemail.from.contact_id
		if (contactId) name	= this.props.extraContacts.find(c => c.id === contactId).name.display
		return (
			<div
				className		= {classes.participant}
				onMouseEnter	= {() => this.props.toggleParticipantsHover(true)}
				onMouseLeave	= {() => this.props.toggleParticipantsHover(false)}
			>
				{name ? <span className={classes.headerItem}>{name}</span> : null}
				{voicemail.from.number ? <span className={classes.headerItem}>{formatPhoneNumber(voicemail.from.number)}</span> : null}
				{voicemail.from.city ? <span className={classes.headerItem}>{voicemail.from.city}, {voicemail.from.state}</span> : null}
			</div>
		)
	}

	renderActions = () => {
		let { classes }	= this.props
		let voicemail	= this.props.voicemail
		let tooltipText	= `Mark ${voicemail.is_new ? '' : 'un'}read`
		return (
			<div className={classes.actions}>
				<span
					className		= {classes.readStatusButton}
					onClick			= {() => this.props.changeReadStatus()}
					onMouseEnter	= {() => this.setUnreadIconHovered(true)}
					onMouseLeave	= {() => this.setUnreadIconHovered(false)}
				>
					<DefaultTooltip
						title			= {tooltipText}
						placement		= 'bottom'
						interactive		= {true}
						leaveDelay		= {1}
						enterTouchDelay	= {0}
					>
						<span>{!voicemail.is_new ? <FlagIcon hover={this.state.unreadIconHovered ? 1 : 0} /> : null}</span>
					</DefaultTooltip>
				</span>
				<VoicemailHeaderDropdownMenu
					deleteVoicemail		= {this.onDeleteClick}
					downloadVoicemail	= {{url: this.props.voicemail.link}}
				/>
			</div>
		)
	}

	render() {
		return (
			<div>
				<HeaderBar>
					{this.renderParticipant()}
					{this.renderActions()}
				</HeaderBar>
				<ConfirmDeleteModal
					itemType					= 'voicemail'
					isShown						= {this.state.showDeleteVoicemailDialog}
					acknowledgedTitle			= 'Permanently delete voicemail'
					notAcknowledgedTitle		= 'Permanently delete voicemail'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
					onClose 					= {this.hideDialog}
					onDelete					= {this.deleteAndSwitchVoicemail}
					size						= 'size1'
				/>
			</div>
		)
	}
}

export default withStyles(styles)(VoicemailHeader)