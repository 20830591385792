// @flow

import * as React from 'react'
import { formatPhoneNumber } from 'phone-numbers'

export default (decoratedHref: string, decoratedText: string, key: number, type: string): React.Node => {
    // If type = '+' return clickableContact component
    return (
        <a target="_blank" href={decoratedHref} key={key}>
            {formatPhoneNumber(decoratedText)}
        </a>
    )
}