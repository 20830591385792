import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchView } from '../actions/view.js'
import { switchFax } from '../actions/faxes.js'
import HeaderBar from 'header-bar'
import ConfirmDeleteModal from 'confirm-delete-modal'
import FaxHeaderDropdownMenu from './FaxHeaderDropdownMenu'

import { FlagIcon } from 'pdc-svg-icons'

import { formatPhoneNumber } from 'phone-numbers'
import { DefaultTooltip, DefaultArrowTooltip } from 'tooltips'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	readStatusButton:	theme.readStatusButton,
	headerItem:			theme.vmFaxHeaderItem,
	participant: {
		cursor: 'pointer'
	},
	actions: {
		display: 'flex'
	}
})

const mapStateToProps = state => ({
	currentFax:	state.currentFax,
	faxLinks:	state.faxLinks
})
const mapDispatchToProps = dispatch => ({
	switchView:	view	=> dispatch(switchView(view)),
	switchFax:	fax		=> dispatch(switchFax(fax))
})

class FaxHeader extends Component {

	state = {
		showDeleteFaxDialog:	false,
		unreadIconHovered:		false
	}

	onDeleteClick = () => this.setState({showDeleteFaxDialog: true})

	hideDialog = () => this.setState({showDeleteFaxDialog: false})

	deleteAndSwitchFax = () => {
		this.props.deleteFax()
		this.hideDialog()
	}

	switchToSelector = () => {
		this.props.switchView('select')
		this.props.switchFax()
	}

	setUnreadIconHovered = unreadIconHovered => this.setState({unreadIconHovered})

	renderParticipant = () => {
		let { classes }			= this.props
		let currentFax			= this.props.currentFax
		let theOtherParticipant	= currentFax.direction === 'in' ? 'from' : 'to'
		let number				= currentFax[theOtherParticipant].number
		let name				= currentFax[theOtherParticipant].name
		let contactId			= currentFax[theOtherParticipant].contact_id
		if (contactId) name		= this.props.extraContacts.find(c => c.id === contactId).name.display
		return (
			<div
				className		= {classes.participant}
				onMouseEnter	= {() => this.props.toggleParticipantsHover(true)}
				onMouseLeave	= {() => this.props.toggleParticipantsHover(false)}
			>
				{name ?
					<DefaultArrowTooltip
						title			= {formatPhoneNumber(number)}
						placement		= 'right'
						interactive		= {true}
						leaveDelay		= {1}
						enterTouchDelay	= {0}
					>
						<span className={classes.headerItem}>{name}</span>
					</DefaultArrowTooltip>
				: null}
				{number ? <span className={classes.headerItem}>{formatPhoneNumber(number)}</span> : null}
				{currentFax[theOtherParticipant].city ? <span className={classes.headerItem}>{currentFax[theOtherParticipant].city}, {currentFax[theOtherParticipant].state}</span> : null}
			</div>
		)
	}

	renderActions = () => {
		let { classes }	= this.props
		let currentFax	= this.props.currentFax
		let tooltipText	= `Mark ${currentFax.is_new ? '' : 'un'}read`
		return (
			<div className={classes.actions}>
				<span
					className		= {classes.readStatusButton}
					onClick			= {() => this.props.changeReadStatus()}
					onMouseEnter	= {() => this.setUnreadIconHovered(true)}
					onMouseLeave	= {() => this.setUnreadIconHovered(false)}
				>
					{!currentFax.is_new ?
						<DefaultTooltip
							title			= {tooltipText}
							placement		= 'bottom'
							interactive		= {true}
							leaveDelay		= {1}
							enterTouchDelay	= {0}
						>
							<span>{!currentFax.is_new && currentFax.direction === 'in' ? <FlagIcon hover={this.state.unreadIconHovered ? 1 : 0} /> : null}</span>
						</DefaultTooltip>
					: null}
				</span>
				{/* <span
					className	= {classes.button}
					onClick		= {this.props.startConversation}
					title		= 'Send Message'
				><ChatIcon/></span> */}
				<FaxHeaderDropdownMenu
					deleteFax	= {this.onDeleteClick}
					downloadFax	= {{url: this.props.faxLinks[this.props.currentFax.id]}}
				/>
			</div>
		)
	}

	render() {
		return (
			<div>
				<HeaderBar
					returnFnc	= {this.switchToSelector}
					smallView	= {this.props.smallView}
				>
					{this.renderParticipant()}
					{this.renderActions()}
				</HeaderBar>
				<ConfirmDeleteModal
					itemType					= 'fax'
					isShown						= {this.state.showDeleteFaxDialog}
					acknowledgedTitle			= 'Permanently delete fax'
					notAcknowledgedTitle		= 'Permanently delete fax'
					acknowledgedMainContent		= ''
					notAcknowledgedMainContent	= ''
					onClose 					= {this.hideDialog}
					onDelete					= {this.deleteAndSwitchFax}
					size						= 'size1'
				/>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxHeader))