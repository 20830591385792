import React, { Component } from 'react'
import BackButton from 'back-button'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import RedDeleteIcon from '../../images/delete-red.svg'
import GrayDeleteIcon from '../../images/delete-gray.svg'
import api from '../../util/api_v5'
import Spinner from 'spinner'
import InfiniteScroller from 'pdc-infinite-scroller'

import { formatPhoneNumber } from 'phone-numbers'
import metadata from 'libphonenumber-js/metadata.full.json';
import { parseNumber as PARSENUMBER} from 'libphonenumber-js';
import { formatNumber as FORMATNUMBER} from 'libphonenumber-js';

import {
	isValidNumber as isValidNumberCustom,
} from 'libphonenumber-js/custom';

export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata);


const styles = theme => ({
	header:			theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	mainWrapper: {
		maxHeight:		'100%',
		display:		'flex',
		flexDirection:	'column',
		'&:not(.small-view)': {
			padding:	'10px 20px',
			maxWidth:	340,
			boxShadow:	'0px 0px 6px gray'
		}
	},
	blockListRow: {
		display:		'flex',
		justifyContent:	'center',
		position:		'relative',
		padding:		'8px 15px'
	},
	blockedItem: {
		borderRadius:	20,
		border:			'1px solid lightgray',
		fontSize:		16,
		padding:		'8px 15px',
		paddingRight:	37,
		width:			'100%',
		'&:focus': {
			outline: 0
		}
	},
	deleteIconWrapper: {
		position:	'absolute',
		right:		20,
		top:		14,
		cursor:		'pointer',
		'&:not(.disabled):hover .gray-delete-icon': {
			display: 'none'
		},
		'&:not(.disabled):not(:hover) .red-delete-icon': {
			display: 'none'
		},
		'&.disabled': {
			cursor: 'not-allowed',
			'& .red-delete-icon': {
				display: 'none'
			}
		}
	},
	buttonWrapper: {
		margin:		'15px 15px',
		display:		'flex',
		justifyContent:	'center'
	},
	errorMessage: {
		color:			'#df1d08',
		margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	},
	blockButton: {
		color:	'red',
		border:	'1px solid red',
		'&:hover': {
			color:				'white',
			backgroundColor:	'red',
			borderColor:		'red'
		},
		'&:focus': {
			outline: 0
		}
	},
	invalidInput: {
		borderColor: 'red'
	},
	scrollMessage: {
		textAlign:	'center',
		color:		'gray'
	}
})

const mapStateToProps = state => ({
	smallView: state.smallView,
})

const mapDispatchToProps = dispatch => ({
})

class BlockList extends Component {

	state={
		blockedItems:	[],
		newBlockedItem:	'',
		invalidInput:	false,
		errorMessage:	'',
		hasMore: 		true
	}

	componentDidMount() {
		this.getBlockedItems(false)
	}


	getBlockedItems = async (scroll=true) => {
		if (this.state.loading) return
		let cursor = 0
		if (this.state.blockedItems.length > 0) {
			cursor = this.state.blockedItems[this.state.blockedItems.length - 1]['cursor']
		}
		this.setState({loading: true})
		let response = await api.getBlocklist(cursor)
		this.setState({loading: false})
		console.log('getBlockedItems', response)
		if (response && response.data.length > 0) {
			this.setState({
				blockedItems: [...this.state.blockedItems, ...response.data],
				hasMore: true
			});
			if (scroll) this.scrollToBottom();
		} else {
			this.setState({hasMore: false})
		}

	}

	removeBlockedItem = async item => {
		if (this.state.loading) return
		this.setState({loading: true})
		await api.removeFromBlocklist(item.voip_blacklist_id)
		this.setState({loading: false})
		let blockedItems = this.state.blockedItems
		let index = blockedItems.indexOf(item);
		if (index !== -1) blockedItems.splice(index, 1);
		console.log('Remove item:', item)
		this.setState({blockedItems})
	}

	addItemToBlockList = async () => {
		
		if (!this.validateNewNumber(this.state.newBlockedItem)){
			this.setState({invalidInput: true, errorMessage: 'Please enter a valid number'})
			return
		}
		this.setState({loading: true, errorMessage: ''})
		let response = await api.addToBlocklist(this.state.newBlockedItem)
		this.setState({loading: false})
		if (response && response.status == 'success'){
			let blockedItem = response.data[0] //always 1 item
			this.setState({
				blockedItems: [...[blockedItem], ...this.state.blockedItems],
				newBlockedItem: '',
				invalidInput: false
			  })
		}
		else if(response){
			this.setState({invalidInput: true, errorMessage: response.message})
		}
		else{
			this.setState({invalidInput: true, errorMessage: 'Something went wrong while blocking number!'})
		}
	}

	validateNewNumber = inputValue => {

		let originalInputValue = inputValue

		if (isValidNumber(inputValue) || isValidNumber(inputValue, 'US')) {

			let parsedNumber;
			if (isValidNumber(inputValue, 'US') && inputValue[0] !== '+1') {
				parsedNumber = PARSENUMBER(inputValue, 'US');
			} else {
				parsedNumber = PARSENUMBER(inputValue);
			}

			inputValue = FORMATNUMBER(parsedNumber, 'International');
			inputValue = inputValue.split(' ').join('');
			return true
		}

		inputValue = originalInputValue.replace(/^0+|0+$/g, '')
		if (this._isShortCode(inputValue)) {
			return true
		}

		if (!inputValue) {
				console.log(' Error message: Type in a phone number.')
				return false;
		}

		console.log(' Error message: The phone number is invalid')
		return false
	}

	clearNewItem = () => {
		this.setState({
			newBlockedItem: '', 
			invalidInput: false,
			errorMessage: ''
		})
	}

	newItemChange = (value) => {
		if (this.state.invalidInput){
			if (this.validateNewNumber(value)){
				this.setState({invalidInput: false, errorMessage: ''})
			}
		}
	
		console.log(value)
		this.setState({newBlockedItem: value})
	}

	_isShortCode(string) {
		return !string.match(/[^0-9]/g) && string.length <= 7 && string.length >= 4 && string[0] !== '0';
	}

	scrollToBottom = () => {
		this.itemsEnd.scrollIntoView({ behavior: 'smooth' });
	}

	render() {
		const { classes } = this.props
		let backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Block list</span>
					</div>
				</div>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
					<div className={`${classes.mainWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
						<div key='new' className={classes.blockListRow}>
							<input
								className	= {`${classes.blockedItem} ${this.state.invalidInput ? classes.invalidInput : ''}`}
								onChange	= {(e) => this.newItemChange(e.target.value)}
								value		= {this.state.newBlockedItem}
								type		= 'text'
								placeholder	= 'New blocked number'
							/>
							<div className={classes.deleteIconWrapper} onClick={() => this.clearNewItem()}>
								<img className='red-delete-icon' src={RedDeleteIcon} alt='Delete' />
								<img className='gray-delete-icon' src={GrayDeleteIcon} alt='Delete' />
							</div>
						</div>
						{this.state.errorMessage ? <div className={classes.errorMessage}>{this.state.errorMessage}</div> : null}
						<div className={classes.buttonWrapper}>
							<Button  
								onClick		={this.addItemToBlockList} 
								variant		='outlined' 
								classes		={{root: classes.blockButton}}
								disabled	= {Boolean(this.state.loading)}
							>
								BLOCK NUMBER
							</Button>
						</div>
						<InfiniteScroller
							reverseScroll	= {false}
							loadMore		= {this.getBlockedItems}
							hasMore			= {this.state.hasMore}
							loader			= {<Spinner/>}
						>
							{this.state.blockedItems.map((item, index) => {
								return (
									<div key={index} className={classes.blockListRow}>
										<span className={classes.blockedItem}>{formatPhoneNumber(item.pattern)}</span>
										<div
											className	= {`${classes.deleteIconWrapper} ${this.state.loading ? 'disabled' : ''}`}
											onClick		= {() => this.removeBlockedItem(item)}
										>
											<img className='red-delete-icon' src={RedDeleteIcon} alt='Delete' />
											<img className='gray-delete-icon' src={GrayDeleteIcon} alt='Delete' />
										</div>
									</div>
								)
							})}
						</InfiniteScroller>
						{this.state.hasMore ? <em className={classes.scrollMessage}>Scroll to load more</em> : null}
						<div style={{float:'left', clear: 'both'}}
							ref={el => this.itemsEnd = el}>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BlockList))