export default function gtmDataPush(data) {
	if (!window.dataLayer) return

	if(window.V5PHONECOM) {
        data['voip_id'] = window.V5PHONECOM.voip_id
        data['voip_phone_id'] = window.V5PHONECOM.voip_phone_id
		data['features'] = window.V5PHONECOM.features
    }

	window.dataLayer.push(data)

	if (!window.FS) return

	let event = data.event || 'event'
	window.FS(event, data)
	console.log(data)

}