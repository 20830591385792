import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
class API {

	static loadCalls = async (filters, limit, cursor, sort) => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot()}/calls/list-calls`
		let response	= await ajax.post(requestUrl, {filters, limit, cursor, sort, no_recordings: true})
		return response.data
	}

	static getCallRecordings = async (recording_ids, voicemail_ids) => {
		let requestUrl	= `${PhoneComUser.getv5ApiRoot()}/calls/get-call-recordings`
		let response	= await ajax.post(requestUrl, {recording_ids, voicemail_ids})
		return response.data
	}

	static makeCall = (callerExtension, calleePhoneNumber, callerCallerId, calleeCallerId) => {
		let body = {
			voip_id:				parseInt(PhoneComUser.getAPIAccountId()),
			caller_extension:		callerExtension,
			callee_phone_number:	calleePhoneNumber,
			caller_caller_id:		callerCallerId,
			callee_caller_id:		calleeCallerId
		}
		return ajax.post(`${PhoneComUser.getv5ApiRoot()}/calls/make-click-to-call`, body)
			.then(response => response.data)
	}
}

export default API