import React, { Component } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { getFormattedTime } from 'time-format'
import { getColorByNumber } from 'colors'
import { formatPhoneNumber } from 'phone-numbers'
import { DefaultArrowTooltip } from 'tooltips'
import { mainTheme } from 'themes'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	contentItems: {
		flexShrink:	'0',
		height:		'100%',
		overflow:	'hidden',
		boxSizing:	'border-box',
		width:		'100%'
	},
	contentItemsTimeSection: {
		margin:		'30px 0'
	},
	contentItemsSenderSection: {
		margin:			'8px 0',
		overflow:		'hidden',
		width:			'auto',
		display:		'flex',
		flexDirection:	'column',
		'&.inbound': {
			alignItems:	'flex-start'
		},
		'&.outbound': {
			alignItems:	'flex-end'
		}
	},
	contentItemSenderContainer: {
		height:			'100%',
		verticalAlign:	'bottom',
		marginTop:		'15px'
	},
	sectionStartTime: {
		marginBottom:	15,
		fontSize:		10.5,
		fontWeight:		500,
		lineHeight:		1.24,
		letterSpacing:	0.2,
		textTransform:	'uppercase',
		'& .section-date': {
			fontWeight:	500,
			color:		theme.messagesApp.contentItems.dateSectionColor
		},
		'& .section-time': {
			fontWeight:	600,
			color:		theme.messagesApp.contentItems.timeSectionColor
		}
	},
	userIconContainer: {
		position:	'relative',
		width:		'40px',
		display:	'inline-block',
		float:		'left',
		height:		'40px',
		top:		'100%',
		margin:		'40px 0px 10px 10px'
	},
	userIconBackground: {
		float:				'left',
		position:			'absolute',
		bottom:				'0',
		width:				'40px',
		height:				'40px',
		backgroundColor:	'#eee',
		borderRadius:		'50%',
		boxSizing:			'border-box'
	},
	userIcon: {
		position:		'absolute',
		height:			'85%',
		left:			'50%',
		top:			'50%',
		borderRadius:	'50px',
		transform:		'translate(-50%,-50%)'
	},
	sectionSenderName: {
		margin:		'2px 0 0',
		fontSize:	'12px',
		fontWeight:	500,
		color:		'#777'
	}
})

class ContentItems extends Component {

	splitItemsByTimeSections = () => {

		let items = this.props.items
		if (!items.length) return []

		let timeSections = items.reduce((acc, item, i) => {
			let itemTime = item.created_at;
			if (i === 0 || itemTime - acc.lastItemTime > 1800) { // 30 minutes
				acc.sections.push({
					items: [item],
					firstItemTime: itemTime
				})
			} else {
				acc.sections[acc.sections.length - 1].items.push(item)
			}
			acc.lastItemTime = itemTime
			return acc
		}, {sections: [], lastItemTime: 0}).sections

		return timeSections
	}

	renderTimeSection = (section, key) => {

		let { classes }		= this.props
		let senderSections	= this.splitItemsBySenderSections(section.items).map((s, i) => this.renderSenderSection(s, `${i}${key}`))
		let formattedTime	= getFormattedTime(section.firstItemTime, true)
		let [date, time]	= this.separateDateAndTime(formattedTime)

		return (
			<section className={classes.contentItemsTimeSection} key={key}>
				<div className={classes.sectionStartTime}>
					<center><span className='section-date'>{date}</span> <span className='section-time'>{time}</span></center>
				</div>
				{senderSections}
			</section>
		)
	}

	separateDateAndTime = dateTimeString => {
		let stringSplit = dateTimeString.split(' ')
		let timeIndex = null
		stringSplit.forEach((e, i) => {
			if (timeIndex !== null) return
			if (e.includes(':')) {
				timeIndex = i
			}
		})
		if (timeIndex === null) timeIndex = stringSplit.length
		let date = stringSplit.slice(0, timeIndex).join(' ')
		let time = stringSplit.slice(timeIndex, stringSplit.length).join(' ')
		return [date, time]
	}

	splitItemsBySenderSections = items => {

		return items.reduce((acc, item, i) => {

			let itemSender		= item.from
			let itemDirection	= item.direction

			if (i === 0 || itemSender !== acc.lastItemSender || itemDirection !== acc.lastItemDirection) {
				acc.sections.push({
					items:		[item],
					sender:		itemSender,
					direction:	itemDirection
				})
			} else {
				acc.sections[acc.sections.length - 1].items.push(item)
			}

			acc.lastItemSender		= itemSender
			acc.lastItemDirection	= itemDirection
			return acc

		}, {sections: [], lastItemSender: 0, lastItemDirection: null}).sections
	}

	renderSenderSection = (section, key) => {

		let lastItemIndex	= section.items.length - 1
		let senderObj		= this.props.participants.find(p => p.number === section.sender)
		let contact			= this.props.extraContacts.find(c => c.id === senderObj.voip_contact_id)
		let name			= contact ? contact.name.display : ''
		let senderName		= senderObj ? (name || section.sender) : section.sender

		let sectionItems = section.items.map(
			(item, index) => {

				let position = 'middle'
				if (index === 0) {
					position = 'first'
				} else if (index === lastItemIndex) {
					position = 'last'
				}

				return (
					this.props.renderItem(`${index}${key}`, item, senderName, position)
				)
			}
		)

		let color = section.direction === 'in' ? getColorByNumber(section.sender) : mainTheme.messagesApp.contentItems.senderSectionColor
		let { classes } = this.props
		let formattedNumber = formatPhoneNumber(section.sender)

		let senderInfo = (
			<div className={classes.sectionSenderName} style={{'color': color, textAlign: section.direction === 'in' ? 'left' : 'right'}}>
				<DefaultArrowTooltip
					title		= {formattedNumber}
					placement	= {section.direction === 'in' ? 'right' : 'left'}
				>
					<span>
						{formatPhoneNumber(senderName)}
						{/* {section.direction === 'in' ? <ClickableContact name={senderObj.name} num={section.sender}/> : null} */}
					</span>
				</DefaultArrowTooltip>
			</div>
		)

		return (
			<div className={classes.contentItemSenderContainer} key={key}>
				<div className={`${classes.contentItemsSenderSection} ${section.direction}bound`}>
					{senderInfo}
					{sectionItems}
				</div>
			</div>
		)
	}

	renderUserIcon = color => {

		let { classes } = this.props

		return (
			<section className={classes.userIconContainer}>
				<div className={classes.userIconBackground} style={{'backgroundColor': color}}>
					<img
						className={classes.userIcon}
						src={this.props.userIcon}
						alt='User Icon'
					/>
				</div>
			</section>
		)
	}

	render() {

		let { classes } = this.props

		return (
			<div className={classes.contentItems} id='content-items'>
				<InfiniteScroller
					reverseScroll	= {this.props.reverseScroll}
					loadMore		= {this.props.loadMore}
					hasMore			= {this.props.hasMoreItems}
					loader			= {<Spinner/>}
					itemsCount		= {this.props.items.length}
					styles			= {{padding: '0 20px'}}
				>
					{this.splitItemsByTimeSections().map(this.renderTimeSection)}
				</InfiniteScroller>
			</div>
		)
	}
}

export default withStyles(styles)(ContentItems)