import React, { Component } from 'react'
import { connect } from 'react-redux'
import AudioPlayer from 'audio-player'
import { avatarSingleSvg } from 'pdc-icons'
import { updateVoicemail, switchVoicemail, removeContactFromVoicemails, addContactsToVoicemails } from '../actions/voicemails'
import { getFormattedTime, getFormattedTime2 } from 'time-format'
import { formatPhoneNumber } from 'phone-numbers'
import { iconBackgroundStyle } from 'colors'
import VoicemailHeader from './VoicemailHeader'
import ParticipantsModal from 'participants-modal'
import EditContactModal from 'edit-contact-modal'
import api from '../util/api_v5'
import LoadingBar from 'loading-bar'
import gtmDataPush from 'gtm-events'
import { withStyles } from '@material-ui/core'

const mapStateToProps = state => ({
	voicemails:			state.voicemails,
	currentVoicemail:	state.currentVoicemail,
	smallView:			state.smallView
})

const mapDispatchToProps = dispatch => ({
	updateVoicemail:				voicemail	=> dispatch(updateVoicemail(voicemail)),
	switchVoicemail:				voicemail	=> dispatch(switchVoicemail(voicemail)),
	addContactsToVoicemails:		contacts	=> dispatch(addContactsToVoicemails(contacts)),
	removeContactFromVoicemails:	contactId	=> dispatch(removeContactFromVoicemails(contactId))
})

const styles = theme => ({
	voicemailContent: {
		width:			'100%',
		display:		'flex',
		flexDirection:	'column',
		position:		'relative'
	},
	mainContentWrapper: {
		overflowY:		'auto'
	},
	mainContent: {
		padding:		'50px 0',
		flex:			1,
		display:		'flex',
		flexDirection:	'column',
		height:			'fit-content',
		position:		'relative',
		width:			'100%',
		maxWidth:		475,
		margin:			'0 auto',
		'&.small-view': {
			padding:	'0 20px'
		}
	},
	voicemailInfoBar: {
		width:		'100%',
		padding:	'8px 20px',
		display:	'flex',
		alignItems:	'center',
		boxShadow:	theme.palette.primary.flatBottomShadow,
		color:		'black'
	},
	dateTimeInfo: {
		flex:				1,
		display:			'flex',
		justifyContent:		'space-between',
		alignItems:			'center',
		paddingRight:		15,
		minWidth:			'fit-content'
	},
	contactInfo: {
		paddingLeft:	15,
		color:			theme.palette.tertiary.lite
	},
	itemIcon: {
		position:			'relative',
		width:				100,
		height:				100,
		minHeight:			100,
		backgroundColor:	'#eee',
		borderRadius:		'50%',
		boxSizing:			'border-box',
		margin:				'5px 0 5px auto'
	},
	iconImage: {
		position:		'absolute',
		height:			'100%',
		left:			'50%',
		top:			'50%',
		borderRadius:	'50px',
		transform:		'translate(-50%, -50%)'
	},
	timeInfo: {
		fontSize:		16,
		lineHeight:		1.31,
		letterSpacing:	-0.1,
		color:			'black',
		'& .date-time': {
			paddingRight:	10,
			borderRight:	'1px solid #b6c0c5'
		},
		'& .audio-length': {
			marginLeft:	10
		}
	},
	fromInfo: {
		marginTop:		10,
		fontSize:		10.5,
		fontWeight:		600,
		lineHeight:		0.95,
		letterSpacing:	-0.1,
		'& span:not(:first-child):last-child': {
			marginLeft: 10
		}
	},
	audioPlayerWrapper: {
		margin:	'25px 0'
	},
	transcript: {
		margin:			'20px 0',
		overflowY:		'auto',
		display:		'flex',
		maxHeight:		'100%'
	},
    dividerStyle:{
        borderTop: theme.palette.primary.flatBorder,
        margin: 0

    }
})

class VoicemailContent extends Component {

	state = {
		iconColor:				null,
		voicemailUrl:			null,
		readyToPlay:			false,
		timestamp:				0,
		editContact:			null,
		hoverOverParticipants:	false
	}
	currentId = null

	componentDidMount() {
		this.init()
		if (this.props.currentVoicemail) {
			this.setState({timestamp: getFormattedTime(this.props.currentVoicemail.created_at, true)})
			this.timeRefreshInterval = setInterval(this.formatTime, 6000)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentVoicemail && (!prevProps.currentVoicemail || prevProps.currentVoicemail.id !== this.props.currentVoicemail.id)) {
			this.formatTime()
			this.setState({readyToPlay: false})
		}
		this.init()
		this.updateEditContactIfNeeded(prevProps)
	}

	updateEditContactIfNeeded = prevProps => {
		if (prevProps.extraContacts.length === this.props.extraContacts.length || !this.state.editContact || this.state.editContact.id) return
		let editContact	= this.state.editContact
		let number		= editContact.number
		let contactId	= null
		this.props.extraContacts.forEach(cd => {
			if (cd.numbers.find(n => n.number === number)) contactId = cd.id
		})
		if (!contactId) return
		editContact.id = contactId
		this.setState({editContact})
	}

	componentWillUnmount() {
		clearInterval(this.timeRefreshInterval)
	}

	formatTime = () => {
		let timestamp = getFormattedTime(this.props.currentVoicemail.created_at, true)
		if (timestamp !== this.state.timestamp) this.setState({timestamp})
	}

	init = () => {
		if (this.props.currentVoicemail && this.props.currentVoicemail.id !== this.currentId) {
			this.currentId = this.props.currentVoicemail.id

			this.getVoicemailLink()

			this.setState({
				iconColor: iconBackgroundStyle([this.props.currentVoicemail.fromNumber])
			})
		}

		if (this.props.voicemails.items && this.props.voicemails.items.length && !this.props.currentVoicemail) {
			let voicemail = this.props.voicemails.items[0]
			this.props.switchVoicemail(voicemail)
		}
	}

	onPlay = () => {
		gtmDataPush({
			event:	'voicemail-played',
			device:	this.props.smallView ? 'mobile' : 'desktop'
		})
		if (this.props.currentVoicemail.is_new) this.props.changeReadStatus()
	}

	startConversation = () => {
		let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath = `/e${extensionId}/messages/new-conversation/${this.props.currentVoicemail.fromNumber}`
		this.props.redirect(redirectPath)
	}

	getVoicemailLink() {
		let currentVoicemail = this.props.currentVoicemail
		if (currentVoicemail && !currentVoicemail.link) {
			 api.getVoicemailLink(currentVoicemail.id).then( url =>{
				let voicemail = this.props.currentVoicemail
				if (voicemail) {
					voicemail.link = url
					this.props.updateVoicemail(voicemail)
				}
			})
		}
	}

	formatDuration = () => {
		let duration	= this.props.currentVoicemail.duration
		let minutes		= parseInt(duration / 60)
		let seconds		= duration - (minutes * 60)
		return `${minutes} min ${seconds} sec`
	}

	onReadyToPlay = () => this.setState({readyToPlay: true})

	editContact = (id, number) => {
		this.setState({editContact: {id, number: id ? '' : number}})
	}

	getContact = () => {
		let extraContacts	= this.props.extraContacts
		let editContact		= this.state.editContact
		let contactId		= editContact ? editContact.id : null
		if (!editContact || !contactId || !extraContacts) return null
		let contact			= null
		this.props.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
		return contact
	}

	saveContact = contact => {
		let extraContacts	= this.props.extraContacts
		let isNew			= !Boolean(extraContacts.find(c => c.id === contact.id))
		this.props.updateContact(contact)
		if (isNew) this.props.addContactsToVoicemails([contact])
		this.setState({editContact: null})
	}

	deleteContact = contactId => {
		this.props.removeContactFromVoicemails(contactId)
		this.props.deleteContact(contactId)
	}

	toggleParticipantsHover = hoverOverParticipants => {
		if (this.state.hoverOverParticipants !== hoverOverParticipants) {
			this.setState({hoverOverParticipants})
		}
	}

	renderAllParticipants = () => {
		let currentVoicemail	= this.props.currentVoicemail
		let myNumber			= {number: 'Private'}
		let otherNumber			= {number: currentVoicemail.from.number}
		let extraContacts		= this.props.extraContacts
		extraContacts.forEach(c => {
			if (otherNumber.name) return
			let contactNumbers = c.numbers.map(n => n.number)
			if (contactNumbers.includes(otherNumber.number)) {
				otherNumber.name		= c.name.display
				otherNumber.contactId	= c.id
			}
		})
		if (!otherNumber.name && currentVoicemail.from.name) otherNumber.name = currentVoicemail.from.name
		let extensionPhoneNumbers = this.props.extension.phone_number
		if (extensionPhoneNumbers[myNumber.number]) myNumber.numberNickname = extensionPhoneNumbers[myNumber.number].name

		return (
			<ParticipantsModal
				selectedNumber		= {myNumber.number}
				myNumbers			= {[myNumber]}
				otherNumbers		= {[otherNumber]}
				participantsHovered	= {this.state.hoverOverParticipants}
				editContact			= {this.editContact}
				changeNumber		= {() => {}}
			/>
		)
	}

	render() {

		let { classes }			= this.props
		let currentVoicemail	= this.props.currentVoicemail
		if (!currentVoicemail) return ''

		return (
			<div className={classes.voicemailContent}>
				<VoicemailHeader
					voicemail				= {currentVoicemail}
					deleteVoicemail			= {this.props.deleteVoicemail}
					changeReadStatus		= {this.props.changeReadStatus}
					startConversation		= {this.startConversation}
					toggleParticipantsHover	= {this.toggleParticipantsHover}
					extraContacts			= {this.props.extraContacts}
				/>

				{this.renderAllParticipants()}

				<div className={classes.voicemailInfoBar}>
					<div className={classes.dateTimeInfo}>
						<span>{getFormattedTime2(this.props.currentVoicemail.created_at, true)}</span>
					</div>
					<div className={classes.contactInfo}>{formatPhoneNumber(currentVoicemail.to)}</div>
				</div>

				<div className={classes.mainContentWrapper}>
					<div className={classes.mainContent}>
						<div className={classes.itemIcon} style={this.state.iconColor}>
							<img className={classes.iconImage} src={avatarSingleSvg} alt='User Icon' />
						</div>
						<div className={classes.timeInfo}>
							<span className='date-time'>{this.state.timestamp}</span>
							<span className='audio-length'>{this.formatDuration()}</span>
						</div>
						<div className={classes.fromInfo}>
							{currentVoicemail.from.number ? <span>{formatPhoneNumber(currentVoicemail.from.number)}</span> : null}
							{currentVoicemail.from.city ? <span>{currentVoicemail.from.city}</span> : null}
						</div>
						<div className={classes.audioPlayerWrapper}>
							{this.props.currentVoicemail.link ?
								<div style={{display: this.state.readyToPlay ? 'block' : 'none'}}>
									<AudioPlayer
										key				= {currentVoicemail.id}
										url				= {this.props.currentVoicemail.link}
										onPlay			= {this.onPlay}
										onReadyToPlay	= {this.onReadyToPlay}
										duration		= {this.props.currentVoicemail.duration}
									/>
								</div>
							: null}
							{!this.props.currentVoicemail.link || !this.state.readyToPlay ? <LoadingBar/> : null}
						</div>
						<hr className={classes.dividerStyle}/>
						<div className={`${classes.transcript} fs-block`}>{currentVoicemail.transcript || '[Transcript not available]'}</div>
					</div>
				</div>

				<EditContactModal
					type				= {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
					onClose				= {() => this.setState({editContact: null})}
					fixedNumber			= {this.state.editContact ? this.state.editContact.number : null}
					contact				= {this.getContact()}
					contactGroupTypes	= {this.props.contactGroupTypes}
					saveContact			= {this.saveContact}
					deleteContact		= {this.deleteContact}
				/>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(VoicemailContent))