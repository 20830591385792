import React, { Component } from 'react'
import Spinner from 'spinner'

class InfiniteScroller extends Component {

	state = {loadingMore: false}

	componentDidMount() {
		// trigger distance of more than 0 makes scroller jump when loading more items
		this.triggerDistance = this.props.triggerDistance || 0

		if (this.props.reverseScroll && this.scroller) {
			this.scroller.scrollTop = this.scroller.scrollHeight
		}
	}

	componentDidUpdate(prevProps) {
		const prevItemsCount	= prevProps.itemsCount || prevProps.children.length
		const itemsCount		= this.props.itemsCount || this.props.children.length
		if (itemsCount !== prevItemsCount && this.scroller && !this.inLoadingRange()) {
			this.scroller.scrollTop = this.props.reverseScroll ? this.scroller.scrollHeight : 0
		}
	}


	inLoadingRange = () => {
		if (this.props.reverseScroll) {
			if (Math.floor(this.scroller.scrollTop - this.triggerDistance) <= 0) {
				return true
			}
		} else {
			let scrollBottom = this.scroller.scrollTop + this.scroller.offsetHeight
			if (Math.ceil(scrollBottom + this.triggerDistance) >= this.scroller.scrollHeight) {
				return true
			}
		}
		return false
	}

	handleScroll = () => {

		if (!this.scroller || this.state.loadingMore || !this.props.hasMore) return
		if (!this.inLoadingRange()) return
		let scroller = this.scroller;
		this.setState({loadingMore:true})
		let prevHeight = this.scroller.scrollHeight
		let prevScrollTop = this.scroller.scrollTop

		this.props.loadMore().then(() => {
			let heightDiff = scroller.scrollHeight - prevHeight
			let newScrollTop = prevScrollTop
			if (this.props.reverseScroll) {
				newScrollTop += heightDiff
			}
			this.setState({loadingMore:false}, () => this.scroller.scrollTop = newScrollTop)
		})
	}

	getLoader() {
		return (
			<div className='pdc-loader-wrapper'>
				{this.props.loader ? this.props.loader : <Spinner/>}
			</div>
		)
	}

	renderLoader = () => this.state.loadingMore ? this.getLoader() : null

	render() {
		let testIdProps = this.props['data-test-id'] ? {'data-test-id': this.props['data-test-id']} : {}
		return (
			<div
				className	= 'infinite-scroller'
				onScroll	= {this.handleScroll}
				onWheel		= {this.handleScroll}
				ref			= {scroller => this.scroller = scroller}
				style		= {this.props.styles || {}}
				{...testIdProps}
			>
				{this.props.reverseScroll ? this.renderLoader() : null}
				{this.props.children}
				{!this.props.reverseScroll ? this.renderLoader() : null}
			</div>
		);
	}
}

export default InfiniteScroller;