import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
	static loadFaxes = async (offset, limit, filters, sort) => {
		let requestUrl		= `${PhoneComUser.getv5ApiRoot()}/faxes/list-faxes`
		let response		= await ajax.post(requestUrl, {filters, sort, limit, offset})
		return response.data
	}

	static isFaxConfigured = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/is-fax-configured`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id, extension_id: phonecom.voip_phone_id}).then(response => response.data.fax_configured_setup)
		})
	}

	static markFaxRead = (fax_id, is_new, fax_type) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/mark-fax-read`
		return ajax.post(requestUrl, {fax_id, is_new, fax_type}).then(response => !response.data.errorMessage)
	}

	static deleteFax = (fax_id, direction) => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/delete-fax`
		return ajax.post(requestUrl, {fax_id, direction}).then(response => !response.data.errorMessage)
	}

	static getFaxLink = uuid => {
		let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/get-fax-link`
		return ajax.post(requestUrl, {uuid: uuid, type: 'inbound'}).then(response => response.data)
	}

	static sendFax = (toNumber, media) => {
		let body = {to: toNumber, media}
		return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/send-fax`, body).then(response => response.data ? response.data : response)
	}

	static authorizeUpload = () => ajax.post(`${PhoneComUser.getv5ApiRoot()}/media/authorize-upload`, {}).then(response => response.data)
}

export default API