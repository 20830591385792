import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid/Grid'
import { ExtensionMenuIcon } from 'pdc-svg-icons'

let wordBreak ='break-word'
if (document.documentMode || /Edge/.test(navigator.userAgent)) {
	wordBreak = 'break-all' // hack fix for edge and internet explorer, we may want move the if statement to a shared function, called like browserDetector or something
}
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		padding: '15px 12px',
		height: '100px',
		backgroundColor: theme.palette.tertiary.dark,
		'&:hover .extensions-icon': {
			display: 'none',
		},
		'&:hover': {
			color: 'white',
		},
		'&:not(:hover)':{
			color:		theme.drawerUserInfo.extNumberColor,
		},
		'&:not(:hover) .extensions-icon-hover': {
			display: 'none'
		}
	},
	extensionNumber: {
		fontWeight:	600,
		fontStyle:	'italic',
		lineHeight:	theme.drawerUserInfo.extNumberLineHeight,
		marginTop:	'10px',
	},
	extensionNumberFontSize:{
		fontSize:	theme.drawerUserInfo.extNumberFontSize
	},
	extensionNumberFontSizeSmall:{
		fontSize:	theme.drawerUserInfo.extNumberFontSizeSmall
	},
	extensionButton: {
		display: 'flex'
	},
	extensionName: {
		fontSize:		theme.drawerUserInfo.extNameFontSize,
		fontWeight:		600,
		wordBreak:		wordBreak,
		color:			theme.drawerUserInfo.textColor,
		width:			'125px',
		lineHeight:		1.25,
		textTransform:	'uppercase',
		hyphens: 'auto',
	},
	drawerUserInfoDivider: {
		marginRight: 5,
		'& .extensions-icon, & .extensions-icon-hover': {
			width: '65px'
		}
	},
	callerId: {},
}))





export default function UserInfoCard(props) {
	const classes = useStyles()
    const extNumberFontSizeClass = ((props.extension && String(props.extension).length > 5)? classes.extensionNumberFontSizeSmall : classes.extensionNumberFontSize);
    const extName = props.extension_name

	return (
		<div className={classes.root}>
			<Grid
				container
				direction="row"
				justify="flex-start"
				alignItems="flex-start"
			>
				<Grid item xs={4} container
					direction="column"
					justify="flex-start"
					alignItems="flex-start"
					onClick={props.onOpen}
					small={"false"}
					spacing={0}
					className={classes.drawerUserInfoDivider}
				>
					<Typography variant="subtitle1" className={classes.extensionButton} gutterBottom={false}>
						<ExtensionMenuIcon className='extensions-icon' />
						<ExtensionMenuIcon className='extensions-icon-hover' type='hover' />
					</Typography>
					<Typography  className={`${classes.extensionNumber} ${extNumberFontSizeClass}`} variant='subtitle1' >
						{`${props.extension}`}
					</Typography>
				</Grid>
				<Grid item xs container
					direction="column"
					justify="flex-start"
					alignItems="flex-start"
				>
					<Grid item>
						<Typography  align={'left'} className={classes.extensionName}>
							{extName}
						</Typography>
					</Grid>
					{/*<Grid item>*/}
					{/*<Typography variant="subtitle2" className={classes.callerId}>*/}
					{/*{callerId}*/}
					{/*</Typography>*/}
					{/*</Grid>*/}

				</Grid>
			</Grid>
		</div>
	)
}