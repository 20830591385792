import React from 'react';
// import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import InlineExtensionSelector from "inline-extension-selector";
import ControlPanelLinks from "./ControlPanelLinks";
import UserInfoCard from './UserInfoCard';
import extBtn from '../images/btn-next@2x.png'
import extBtnWhite from '../images/btn-next-wht@2x.png'
import btnPrev from '../images/btn-back.png'
import btnPrevWhite from '../images/btn-back-hover.png'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// I moved this out because I see it in other places. This goes in theme
const popTheme = {
    borderRadius: '4px',
    boxShadow: '2.5px 2.5px 0 0 rgba(43, 58, 67, 0.5)',
    border: 'solid 1.5px #000000',
    backgroundColor: '#ffffff'
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    typography: {
      padding: theme.spacing(2),
    },
    popper: {
        zIndex: 1
    },
    paper: {
        height: '100%',
        ...popTheme
    },
    verticalRule: {
        width: '0.5px',
        backgroundColor: '#d8d8d8',
        position: 'absolute',
        left: '100%',
        height: '90%',
        borderLeft: '1px solid lightgray',
        margin: '5% auto'
    },
    infoCard: {
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    profileCardFull: {
        height: '100%',
        display: 'inline-block',
        width: '100%',
        margin: '0 auto',
        padding: '2%',
        float: 'left',
    },
    profileCardHalf: {
        height: '100%',
        display: 'inline-block',
        width: '48%',
        margin: '0 auto',
        padding: '2%',
    },
    selectExtensionButton: {
        'border-radius': '25px',
        'font-size': '18px',
        'font-weight': '500',
        'padding': '9px 18px',
        'border': `2px solid ${theme.palette.primary.main}`,
        'color': theme.palette.primary.main,
        'margin': '16px 0 100px 0',
        'display': 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    mainElement: {
        'height': '100%',
        'overflow': 'auto'
    },
    mainElementMobile: {
        'width': '50%',
        'padding-right': '24px'
    },
    gotoMainViewBtn:{
        '& .back-arrow-image-white': {
            minWidth: '50px',
            minHeight: '50px'
        },
        "&:hover" :{
            borderRadius: '25px',
            background: theme.palette.primary.main,
            color: 'white',
        },
        '&:not(:hover) .arrow-image-white': {
            display: 'none'
        },
        '&:hover .arrow-image': {
            display: 'none'
        },
        '&:not(:hover) .back-arrow-image-white': {
            display: 'none'
        },
        '&:hover .back-arrow-image': {
            display: 'none'
        }
    }
}));

// todo finish this component
// Add switch between selection selector and main view on mobile
export default function ProfileMenu(props) {
	const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const cardRef = React.createRef();
    const classes = useStyles();
    const isMobileView = props.screenViewType.isMobileView
    const isTabletView = props.screenViewType.isTabletView
    const isDesktopView = !isMobileView && !isTabletView
    const dialogContentRef = React.createRef()

    const handleClick = event => {
        if (event.currentTarget && anchorEl !== undefined) {
            setAnchorEl(prev => (prev ? null : cardRef.current))
        }
    }

    const extensionSelectHandler = (data) => {
        setAnchorEl(null);
        props.extensionSelectHandler(data);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'no-transition-popper' : undefined;
    const shouldShowSelector = props.userInfo.extensions.length > 1;

    const handleClose = (a) => {
        setAnchorEl(null);
    }

    const handleClickAway = () => {
        if(isDesktopView) {
            setAnchorEl(null);
        }
    };

    const Dialog = withStyles(theme => ({
        paper: {
            'overflow-x': 'hidden',
            'margin': '36px',
            'width': 'calc(100% - 72px)'
        }
    }))(MuiDialog)

    const DialogContent = withStyles(theme => ({
        root: {
            'overflow-x': 'hidden',
            'width': '200%',
            'display': 'flex',
            'padding': '8px 24px'
        }
    }))(MuiDialogContent)

    const gotToExtensionView = () => {
        if (isDesktopView) return
        let dialogPaperElement = dialogContentRef.current
        dialogPaperElement.parentElement.scroll({left: dialogPaperElement.offsetWidth, behavior: 'smooth' })
    }

    const goToMainView = () => {
        if (isDesktopView) return
        let dialogPaperElement = dialogContentRef.current
        dialogPaperElement.parentElement.scroll({left: 0, behavior: 'smooth' })
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>

        <div className={classes.root}>

            {(theme.drawerUserInfo.replaceWithLogo) ?
                <img style={{ minHeight: 100, width: '100%', padding: 5, backgroundColor: theme.drawerUserInfo.replacement.backgroundColor}}
                     src={theme.drawerUserInfo.replacement.logo} alt='logo'
                />
                :
                    <div className={classes.infoCard} ref={cardRef} onClick={handleClick}>
                        <UserInfoCard {...props.currentUser}/>
                    </div>
            }

            {(isMobileView || isTabletView) ? 
            <Dialog
                open={open}
                fullWidth
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'>
                <DialogContent ref={dialogContentRef}>
                    <div className={`${classes.mainElement} ${classes.mainElementMobile}`}>
                       {shouldShowSelector && ( <div className={`${classes.selectExtensionButton} ${classes.gotoMainViewBtn}`}
                            onClick={gotToExtensionView}>
                            <span>Select Extension</span>
                            <div style={{display: 'flex'}}>
                                <img className='arrow-image' src={extBtn} alt='next' />
                                <img className='arrow-image-white' src={extBtnWhite} alt='next' />
                            </div>
                        </div> )}
                        <ControlPanelLinks
                            userInfo        = {props.userInfo}
                            screenViewType  = {props.screenViewType}
                            logout          = {props.logout}
                        />
                    </div>
                    <div style={{background: 'white', width: '50%', paddingLeft: '24px'}}>
                        <div style={{display: 'inline-block', margin: '16px 0'}}  
                            className={classes.gotoMainViewBtn}
                            onClick={goToMainView}>
                            <img className='back-arrow-image' src={btnPrev} alt='prev' />
                            <img className='back-arrow-image-white' src={btnPrevWhite} alt='prev' />
                        </div>
                        <InlineExtensionSelector
                            extensionData={props.userInfo.extensions}
                            onExtensionSelect={extensionSelectHandler}
                            screenViewType={props.screenViewType}
                        />
                    </div>
                </DialogContent>
            </Dialog> : 
            <Popper
                id={id} open={open} anchorEl={anchorEl} className={classes.popper}
                placement="bottom-end" disablePortal={true}
            >
                <Paper className={classes.paper} style={{width: shouldShowSelector ? '200%' : '100%', height:"auto"}}>
                    <div style={{width: '100%', height: '100%', display: 'flex'}}>
                        {shouldShowSelector && (
                            <div className={classes.profileCardHalf}>
                            <InlineExtensionSelector
                                extensionData={props.userInfo.extensions}
                                onExtensionSelect={extensionSelectHandler}
                                screenViewType={props.screenViewType}
                            />
                            </div>
                        )}

                        {shouldShowSelector && (<div className={classes.verticalRule}></div>)}

                        <div className={shouldShowSelector ? classes.profileCardHalf : classes.profileCardFull}>
                            <ControlPanelLinks
                                userInfo        = {props.userInfo}
                                screenViewType  = {props.screenViewType}
                                logout          = {props.logout}
                            />
                        </div>
                    </div>
                </Paper>
            </Popper>
            }
        </div>
        </ClickAwayListener>
    )
}
