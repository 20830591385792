import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
	static loadContacts = async (filters={}, limit=20, cursor=null) => {
		await getPhoneCom()
		let data = {filters, limit}
		if (cursor) data.cursor = cursor
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/load-contacts`
		return ajax.post(requestUrl, data).then(response => response.data)
	}

	static createContact = async data => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/create-contact`
		return (await ajax.post(requestUrl, data)).data
	}

	static updateContact = async data => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/update-contact`
		return await ajax.post(requestUrl, data)
	}

	static deleteContact = async contact_id => {
		await getPhoneCom()
		let requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/delete-contact`
		return await ajax.post(requestUrl, {contact_id})
	}
}

export default API 