import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5.js'
import Api from 'api'
import PhoneComUser from 'phone-com-user'
import CallContent from './CallContent'
import CallsSelector from './CallsSelector'
import StartNewButton from 'start-new-button'
import MakeCall from './MakeCall'
import ResizeAware from 'react-resize-aware'
import { setSmallView, switchView } from '../actions/view'
import { switchCall, updateCalls, addRecordings, addContactsToCalls } from '../actions/calls'
import { switchExtension } from '../actions/pdcuser'
import LoaderFull from 'loader-full'
import { withStyles } from '@material-ui/core'
let threshold = 768

const mapStateToProps = state => ({
	calls:					state.calls,
	smallView:				state.smallView,
	currentView:			state.currentView,
	currentExtensionRedux:	state.currentExtension
})
const mapDispatchToProps = dispatch => ({
	setSmallView:		boolVal		=> dispatch(setSmallView(boolVal)),
	switchView:			view		=> dispatch(switchView(view)),
	switchCall:			call		=> dispatch(switchCall(call)),
	updateCalls:		call		=> dispatch(updateCalls(call)),
	addRecordings:		recordings	=> dispatch(addRecordings(recordings)),
	switchExtension:	extension	=> dispatch(switchExtension(extension)),
	addContactsToCalls:	contacts	=> dispatch(addContactsToCalls(contacts))
})

const styles = theme => ({
	appWrapper: {
		display:	'flex',
		height:		'100%',
		position:	'relative'
	},
	callsPanel: {
		display:		'flex',
		flexDirection:	'column',
		minWidth:		theme.selector.width,
		boxShadow:		'0 0 0 1px #e0e0e0',
		position:		'relative',
		'&.small-view': {
			width:		'100%'
		},
		'&:not(.small-view)': {
			maxWidth:	theme.selector.width,
		}
	},
	callsSelectorTitle: {
		fontSize:		20,
		fontWeight:		600,
		lineHeight:		1.25,
		letterSpacing:	-0.2,
		color:			'black',
		padding:		'17px 20px',
		boxShadow:		theme.palette.primary.flatBottomShadow
	}
})

class App extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading:	false,
			loadedOnce:	false
		}

		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	componentDidMount() {
		this._ismounted = true
		// this.props.subscribeForNotifications('calls', this.callUpdate)
		this.initialLoad()
	}

	componentWillUnmount() {
		this._ismounted = false
	}

	componentDidUpdate() {
		if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(this.props.extension.extension_id)
			// this.props.resetSubscription(true)
			this.initialLoad(true)
		}
	}

	initialLoad = async force => {
		let extensionSwitched = Boolean(!this.props.currentExtensionRedux || this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id)
		if (!extensionSwitched && this.props.calls.items && !force) return this.setState({loadedOnce: true})
		if (extensionSwitched) this.props.switchExtension(this.props.extension)
		this.setState({loading: true})
		this.props.contactsUtil.reload()
		await this.getCalls()
		this.getRecordings()
		await this.loadExtraContacts()
		this.setState({loading: false, loadedOnce: true})
	}

	loadExtraContacts = async () => {
		let calls		= this.props.calls
		let callsItems	= calls.items
		let phoneNumbers		= []
		// Collect all of the phone numbers which are not connected to a contact
		callsItems.forEach(c => {
			let direction = c.type === 'outgoing' ? 'to' : 'from'
			if (c[direction].contact_id) return
			phoneNumbers.push(c[direction].number)
		})
		if (phoneNumbers.length === 0) return
		// Remove duplicates
		phoneNumbers			= Array.from(new Set(phoneNumbers))
		let filters				= {keyword: phoneNumbers}
		let extraContacts		= await this.props.contactsUtil.loadExtraContacts(filters)
		this.props.addContactsToCalls(extraContacts)
	}

	getCalls = async () => {
		if (!this._ismounted) return // console.log('Calls App.js got unmounted')
		// limit so based on height so it always gets filled
		let extensionId		= PhoneComUser.getExtensionId()
		let responseCalls	= await api.loadCalls({}, parseInt(window.innerHeight / 50))
		responseCalls.items.forEach(c => {
			c.recording.loading = true
			c.voicemail.loading = true
		})
		if (extensionId !== PhoneComUser.getExtensionId()) {
			// This may happen if you change the extension while this extension calls are being loaded
			return // console.log('The extension got changed so stop.')
		}
		this.props.updateCalls(responseCalls)
		let switchedByUrl = this.openUrlCall()
		this.props.onLoaded()
		if (switchedByUrl) return
		if (!this.props.calls.items || !this.props.calls.items.length) {
			this.props.switchCall()
		} else if (!this.props.smallView) {
			this.props.switchCall(this.props.calls.items[0])
		}
	}

	// If the url path links to a call then open it
	openUrlCall = () => {
		let pathname		= window.location.pathname
		let pathnameSplit	= pathname.split('/').filter(e => e)
		let callId			= undefined
		if (pathnameSplit.length > 2 && pathnameSplit[1] === 'calls') {
			callId			= pathnameSplit[2].substring(1)
		}
		let callItems		= this.props.calls.items
		if (!callItems || !callId) return

		let pathCall = callItems.find(c => c.id === callId)
		if (pathCall) {
			this.props.switchCall(pathCall)
			this.props.switchView('content')
			return true
		}
		return false
	}

	getRecordings = async () => {
		let callItems		= this.props.calls.items
		if (!callItems) return
		let recordingIds	= callItems.map(c => c.recording ? c.recording.id : null).filter(c => c)
		let voicemailIds	= callItems.map(c => c.voicemail ? c.voicemail.id : null).filter(c => c)
		let recordings		= await api.getCallRecordings(recordingIds, voicemailIds)
		this.props.addRecordings(recordings)
	}

	handleResize = size => {
		let needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink = !this.props.smallView && ( (this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	onMakeCallClick = () => {
		this.props.switchCall()
		this.props.switchView('make_call')
	}

	render() {
		const { classes } = this.props
		return (
			<div className='App'>
				<ResizeAware
					style		= {{height: this.props.standalone ? 'calc(100% - 60px)' : '100%'}}
					onResize	= {this.handleResize}
				>
					{this.state.loading ?
						<div className={classes.loadingDiv}>
							<LoaderFull styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='big'/>
						</div>
					: null}
					{this.state.loadedOnce &&
						<div className={classes.appWrapper}>
							{!this.props.smallView || this.props.currentView === 'select' ?
								<div className={`${classes.callsPanel} ${this.props.smallView ? 'small-view' : ''}`}>

									{/* <StartNewButton app='calls' title='Make a call' onClick={this.onMakeCallClick}/> */}
									{!this.props.smallView ? <div className={classes.callsSelectorTitle}>Calls</div> : null}
									<CallsSelector
										newCall				= {this.state.newCall}
										setNoNewCall		= {this.setNoNewCall}
										smallView			= {this.props.smallView}
										screenViewType		= {this.props.screenViewType}
										deleteCall			= {this.deleteCall}
										changeReadStatus	= {this.changeReadStatus}
										getRecordings		= {this.getRecordings}
										loadExtraContacts	= {this.loadExtraContacts}
										extraContacts		= {this.props.contactsUtil.extraContacts}
									/>
								</div>
							: null}
							{this.props.currentView === 'make_call' ?
								<MakeCall
									extension		= {this.props.extension}
									contactsUtil	= {this.props.contactsUtil}
								/>
							: null}
							{(!this.props.smallView && ['content', 'select'].includes(this.props.currentView)) || this.props.currentView === 'content' ?
								<CallContent
									extension			= {this.props.extension}
									deleteCall			= {this.deleteCall}
									changeReadStatus	= {this.changeReadStatus}
									redirect			= {this.props.redirect}
									extraContacts		= {this.props.contactsUtil.extraContacts}
									contactGroupTypes	= {this.props.contactsUtil.groupTypes}
									updateContact		= {this.props.contactsUtil.updateContact}
									deleteContact		= {this.props.contactsUtil.deleteContact}
								/>
							: null}
						</div>
					}
				</ResizeAware>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))