import { parsePhoneNumber } from 'libphonenumber-js'

// Takes a string and attempts to parse it as a phone number
// Returns arg if not a phone number
function formatPhoneNumber(phoneNumber) {
	let ret
	try {
		let parsedNum = parsePhoneNumber(phoneNumber, 'US')
		if (phoneNumber.includes('+1') || phoneNumber[0] !== '+')
			ret = parsedNum.formatNational()
		else
			ret = parsedNum.formatInternational()
	} catch (error) {
		ret = phoneNumber
	}
	return ret
}

export { formatPhoneNumber }