import React, { Component } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import FaxSelectorItem from './FaxSelectorItem.js'
import NoSetup from 'no-setup'
import Spinner from 'spinner'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import { withStyles } from '@material-ui/core'
import { updateFax, updateFaxes, switchFax } from '../actions/faxes'
import { switchView } from '../actions/view.js'
import { FaxReceivedIcon, FaxSentIcon } from 'pdc-svg-icons'
import { LabelCountIcon } from 'pdc-svg-icons'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'

const mapStateToProps = state => ({
	faxes:		state.faxes,
	currentFax:	state.currentFax,
	smallView:	state.smallView
})

const mapDispatchToProps = dispatch => ({
	updateFax:		fax		=> dispatch(updateFax(fax)),
	updateFaxes:	faxes	=> dispatch(updateFaxes(faxes)),
	switchFax:		fax		=> dispatch(switchFax(fax)),
	switchView:		view	=> dispatch(switchView(view))
})

const styles = theme => ({
	selectorWrapper: {
		boxShadow:		'0 0 0 1px #e0e0e0',
		position:		'relative',
		flex:			1,
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'center',
		minHeight:		0
	},
	newFaxInfoWrapper: {
		position:		'absolute',
		top:			0,
		left:			0,
		right:			0,
		height:			'60px',
		display:		'flex',
		zIndex:			2,
		background:		'linear-gradient(gray, transparent)',
		justifyContent:	'center',
		alignItems:		'center',
		transition:		'top .25s',
		'&>span': {
			fontWeight:		'bold',
			color:			'green',
			background:		'rgba(255,255,255,0.7)',
			padding:		'3px 10px',
			border:			'2px solid green',
			borderRadius:	'17px'
		},
		'&.disappear': {
			top: '-60px'
		}
	},
	noFaxMessage: {
		marginTop:		'15px',
		padding:		'0 10px',
		display:		'inline-block',
		width:			'100%',
		height:			'100%',
		verticalAlign:	'middle',
		textAlign:		'center',
		'&>span': {
			color:		'#b6c0c5',
			fontWeight:	'bold',
			lineHeight:	1.29
		}
	},
	tabsWrapper: {
		padding:		5,
		paddingBottom:	0,
		width:			'100%'
	},
	tabs: {
		display:		'flex',
		width:			'100%',
		borderRadius:	5,
		paddingBottom:	5,
		position:		'relative',
		'& .tab': {
			fontSize:		14,
			fontWeight:		'bold',
			lineHeight:		1.36,
			textAlign:		'center',
			padding:		'8px 10px 3px',
			borderRadius:	5,
			flex:			1,
			zIndex:			1,
			cursor:			'pointer',
			background:		'white',
			position:		'relative',
			'&.selected': {
				background:	theme.faxesApp.tabs.firstTabColor,
				color:		'white',
				cursor:		'default',
				'&.gray': {
					background: theme.faxesApp.tabs.secondTabColor
				}
			},
			'& > svg': {
				width:		18,
				height:		18,
				position:	'absolute',
				left:		15
			},
			'& .label-count-icon-wrapper': {
				position:	'absolute',
				top:		0,
				right:		0,
				display:	'flex',
				'& .unread-text': {
					position:	'absolute',
					right:		'0%',
					fontSize:	10,
					fontWeight:	'bold',
					lineHeight:	1.1,
					marginRight: 6,
					marginTop: 3,
					'&.white-color': {
						color:	'white'
					}
				},
				'& > svg': {
					position:	'absolute',
					right:		1,
					width:		29,
					height:		'initial'
				}
			},
			'&:not(.selected) .label-count-icon-wrapper': {
				// display:	'none'
			}
		}
	},
	tabsBigPlaceholder: {
		height:			20,
		position:		'absolute',
		bottom:			0,
		borderRadius:	5,
		width:			'60%',
		'&.move-left': {
			background:	theme.faxesApp.tabs.firstTabColor,
			left: 0
		},
		'&.move-right': {
			background:	theme.faxesApp.tabs.secondTabColor,
			right: 0
		}
	},
	tabsSmallPlaceholder: {
		position:		'absolute',
		bottom:			0,
		width:			'90%',
		height:			5,
		borderRadius:	5,
		'&.move-left': {
			background:	theme.faxesApp.tabs.secondTabColor,
			right:		'10%'
		},
		'&.move-right': {
			background:	theme.faxesApp.tabs.firstTabColor,
			left:		'10%'
		}
	},
	scrollerWrapper: {
		width:		'100%',
		height:		'100%',
		minHeight:	0
	},
	loadingDiv: theme.loadingDiv
})

const firstTab	= theme.faxesApp.tabs.firstTabName
const secondTab	= theme.faxesApp.tabs.secondTabName
class FaxSelector extends Component {

	state = {
		newFax:			false,
		selectedTab:	null,
		loadingFaxes:	false
	}
	lastNewFax = null

	componentDidUpdate(prevProps) {
		if (this.props.newFax !== null && this.props.newFax !== this.lastNewFax) {
			this.lastNewFax = this.props.newFax
			this.props.setNoNewFax()
			this.setState({newFax: true})
			setTimeout(() => {this.setState({newFax: false})}, 2000)
		}

		if (prevProps.switchTab && prevProps.switchTab !== this.props.switchTab) {
			this.setState({selectedTab: this.props.switchTab})
		}

		if (this.props.currentFax &&
			(!prevProps.currentFax || (prevProps.currentFax.id !== this.props.currentFax.id)) &&
			this.props.currentFax.is_new &&
			!this.props.currentFax.justSent &&
			!this.props.currentFax.dontMarkRead
		) {
			this.props.changeReadStatus()
		}

		if (this.state.selectedTab === null && this.props.currentFax) {
			if (this.props.currentFax.direction === 'in') this.setState({selectedTab: 'received'})
			if (this.props.currentFax.direction === 'out') this.setState({selectedTab: 'sent'})
		}

		if (
			this.props.deletedIncomingFaxes.num && 
			this.props.deletedIncomingFaxes.randomString !== prevProps.deletedIncomingFaxes.randomString
		) this.loadMore('fax-incoming', this.props.deletedIncomingFaxes.num)

		if (
			this.props.deletedOutgoingFaxes.num && 
			this.props.deletedOutgoingFaxes.randomString !== prevProps.deletedOutgoingFaxes.randomString
		) this.loadMore('fax-outgoing', this.props.deletedOutgoingFaxes.num)
	}

	isActive = fax => this.props.currentFax ? fax.id === this.props.currentFax.id : false

	onFaxClick = fax => {
		delete fax.dontMarkRead
		this.props.updateFax(fax)
		this.props.switchFax(fax)
		this.props.switchView('content')
		if (fax.is_new && this.props.currentFax && fax.id === this.props.currentFax.id && !fax.justSent) this.props.changeReadStatus(fax)
	}

	// faxType can be 'fax-incoming' or 'fax-outgoing'
	loadMore = async (faxType, limit=3) => {
		if (this.state.loadingFaxes) return
		let faxes	= this.props.faxes[faxType]
		let offset	= faxes.items.length
		this.setState({loadingFaxes: true})
		let response = await api.loadFaxes(offset, limit, {message_type: faxType})
		faxes.items.push(...response.items)
		response.items = faxes.items
		this.props.updateFaxes(response)
		this.setState({loadingFaxes: false})

		// Load the new storred contacts of each newly-loaded fax and store them in redux
		this.props.loadExtraContacts()
	}

	onTabChange = tabName => {
		this.setState({selectedTab: tabName})
		if (this.props.smallView) return
		let faxType = tabName === 'received' ? 'fax-incoming' : 'fax-outgoing'
		let faxItems = this.props.faxes[faxType].items
		let fax = faxItems.length ? faxItems[0] : null
		if (fax) fax.dontMarkRead = true
		this.props.switchFax(fax)
	}

	renderTab = tabName => {
		let faxes		= this.props.faxes
		let selectedTab	= this.state.selectedTab || theme.faxesApp.tabs.defaultTab
		let faxType		= tabName === 'received' ? 'fax-incoming' : 'fax-outgoing'
		let count		= 0
		if (tabName === 'received') count = faxes[faxType]['unread']
		else {
			faxes[faxType].items.forEach(f => (f.justSent || f.delivery_status === 'failed' && f.is_new) ? count++ : null)
		}
		let isTabSelected	= selectedTab === tabName
		let selectedClass	= isTabSelected ? 'selected' : ''
		let grayClass		= tabName === secondTab ? 'gray' : ''
		let showFlag		= !isTabSelected && tabName === 'sent'
		return (
			<div
				className	= {`tab ${selectedClass} ${grayClass}`}
				onClick		= {() => this.onTabChange(tabName)}
			>
				{selectedTab === tabName ? tabName === 'received' ? <FaxReceivedIcon/> : <FaxSentIcon/> : null}
				<span>{tabName === 'received' ? 'Received' : 'Sent'}</span>
				{(faxes[faxType] && (tabName === 'received' || count)) ?
					<div className='label-count-icon-wrapper'>
						<LabelCountIcon type={showFlag ? 'second' : 'transparent'}/>
						<span className={`unread-text ${showFlag ? 'white-color' : ''}`}>{count || ''}</span>
					</div>
				: null}
			</div>
		)
	}

	renderFaxItems = direction => {
		const { classes }		= this.props
		let selectedTab			= this.state.selectedTab || theme.faxesApp.tabs.defaultTab
		let incomingFaxes		= this.props.faxes['fax-incoming']
		let outgoingFaxes		= this.props.faxes['fax-outgoing']
		let incomingFaxItems	= incomingFaxes.items || []
		let outgoingFaxItems	= outgoingFaxes.items || []
		let hasMoreIncoming		= incomingFaxItems.length ? incomingFaxes.total - incomingFaxItems.length > 0 : false
		let hasMoreOutgoing		= outgoingFaxItems.length ? outgoingFaxes.total - outgoingFaxItems.length > 0 : false
		let faxType				= direction === 'in' ? 'fax-incoming' : 'fax-outgoing'
		let faxItems			= direction === 'in' ? incomingFaxItems : outgoingFaxItems
		return (
			<div className={`${classes.scrollerWrapper} ${selectedTab !== (direction === 'in' ? 'received' : 'sent') ? 'hidden' : ''}`}>
				<InfiniteScroller
					reverseScroll	= {false}
					loadMore		= {() => this.loadMore(faxType)}
					hasMore			= {direction === 'in' ? hasMoreIncoming : hasMoreOutgoing}
					loader			= {<Spinner/>}
					data-test-id	= {`infinite-scroller-${faxType}`}
				>
					{faxItems.map(fax =>
						<FaxSelectorItem
							key					= {fax.id}
							fax					= {fax}
							onClick				= {() => this.onFaxClick(fax)}
							active				= {this.isActive(fax)}
							screenViewType		= {this.props.screenViewType}
							deleteFax			= {this.props.deleteFax}
							changeReadStatus	= {this.props.changeReadStatus}
							extraContacts		= {this.props.extraContacts}
						/>
					)}
				</InfiniteScroller>
			</div>
		)
	}

	render() {

		let { classes }			= this.props
		let incomingFaxes		= this.props.faxes['fax-incoming']
		let outgoingFaxes		= this.props.faxes['fax-outgoing']
		let incomingFaxItems	= incomingFaxes.items || []
		let outgoingFaxItems	= outgoingFaxes.items || []
		let selectedTab			= this.state.selectedTab || theme.faxesApp.tabs.defaultTab

		return (
			<div className={classes.selectorWrapper}>
				{incomingFaxItems.length ? 
					<div className={`${classes.newFaxInfoWrapper} ${this.state.newFax ? '' : 'disappear'}`}>
						<span>New Fax Received</span>
					</div>
				: null}
				<div className={classes.tabsWrapper}>
					<div className={classes.tabs}>
						{this.renderTab(firstTab)}
						{this.renderTab(secondTab)}
						<div className={`${classes.tabsBigPlaceholder} ${selectedTab === secondTab ? 'move-right' : 'move-left'}`}></div>
						<div className={`${classes.tabsSmallPlaceholder} ${selectedTab === secondTab ? 'move-left' : 'move-right'}`}></div>
					</div>
				</div>

				{incomingFaxItems.length ? 
					this.renderFaxItems('in')
				: this.props.isFaxConfigured === null ?
					<div className={classes.loadingDiv}><LoaderFull/></div>
				: this.props.isFaxConfigured ?
					<div className={`${classes.noFaxMessage} ${selectedTab !== 'received' ? 'hidden' : ''}`}>
						<span>You haven't received any faxes</span>
					</div>
				: selectedTab === 'received' ?
					<NoSetup type='faxes'/>
				: null}

				{outgoingFaxItems.length ? 
					this.renderFaxItems('out')
				: <div className={`${classes.noFaxMessage} ${selectedTab !== 'sent' ? 'hidden' : ''}`}>
					<span>{theme.faxesApp.noSentFaxMessage}</span>
				</div>}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxSelector))