import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import 'core-js/es';
import 'es6-promise/auto';

import Communicator from 'communicator';
import {theme} from "get-theme";
import ErrorCatcher from "error-catcher";
import LoginRedirector from "login-redirector";
ReactDOM.render(
    <ErrorCatcher theme={theme}>
      <LoginRedirector>
        <Communicator theme={theme} />
      </LoginRedirector>
    </ErrorCatcher>,
    document.getElementById('root'));

//set up un auth url redirect to sign in



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker && waitingServiceWorker.state !== 'redundant') {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          waitingServiceWorker.postMessage({ type: "WAITING" });
          console.log('reloading for new service worker');
          setTimeout(function(){ window.location.reload() }, 500);
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});
