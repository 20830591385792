import React, {Component} from 'react'

import { connect } from 'react-redux'
import { switchConversation, switchSendNumber, updateConversation } from '../../actions/conversations'
import { switchView } from '../../actions/view'

import api from '../../util/api_v2'

import ConversationSelectorItem from './ConversationSelectorItem'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'

const mapStateToProps = state => {
	return {
		currentConversation:	state.currentConversation,
		conversations:			state.conversations,
		smallView:				state.smallView,
		currentView:			state.currentView
	}
}
const mapDispatchToProps = dispatch => {
	return {
		switchConversation:	conv	=> dispatch(switchConversation(conv)),
		updateConversation:	conv	=> dispatch(updateConversation(conv)),
		switchView:			view	=> dispatch(switchView(view)),
		switchSendNumber:	num		=> dispatch(switchSendNumber(num))
	}
}

class ConversationSelector extends Component {

	componentDidMount = () => {
		this.props.subscribeForNotifications('read_status', this.readStatusHandler)
	}

	readStatusHandler = m => {
		let conv = this.props.conversations.find(c => c.id === m.conversation_id)
		if (!conv.markedUnreadAt || m.read_at > (conv.markedUnreadAt / 1000)) {
			conv.unread_messages = 0
			if (conv.messages) {
				conv.messages.forEach(message => {
					if (!message.read_at) {
						message.read_at = m.read_at
					}
				})
			}
			conv.markedUnreadAt = null
			this.props.updateConversation(conv);
			this.props.changeMessageReadStatus('read', 'all')
			this.props.updateUnreadCounts();
		}
	}

	onConversationClick = conv => {

		// Mark as read
		if (conv.unread_messages > 0) {
			conv.unread_messages = 0
			conv.pauseMarkingRead		= false
			conv.scrollToMessage		= conv.last_unread_message_id
			conv.messages && conv.messages.forEach(m => {
				if (!m.read_at) {
					m.read_at = parseInt((new Date()).getTime()/1000)
				}
			})
			conv.markedUnreadAt = null
			this.props.updateConversation(conv)
			setTimeout(() => {
				if (conv.markedUnreadAt) return
				conv.last_unread_message_id	= null
				this.props.updateConversation(conv)
			}, 2000)
			this.props.changeMessageReadStatus('read', 1)
			api.markRead(conv.id, parseInt((new Date()).getTime()/1000)).then(() => this.props.updateUnreadCounts())
		}

		this.props.switchConversation(conv)
		this.props.switchView('content')

		if (conv.from.length) {
			this.props.switchSendNumber(conv.from[0].number)
		} else {
			this.props.switchSendNumber(null)
		}

		let typeMessageField = document.getElementById('type-message-field')
		if (typeMessageField) typeMessageField.focus()
	}
	isConversationActive = conversation => {
		if (this.props.smallView || this.props.currentView === 'new_conversation') return false
		if (this.props.currentConversation) return conversation.id === this.props.currentConversation.id
		return false
	}

	render() {
		return (
			<div style={{height: '100%'}}>
				<InfiniteScroller
					reverseScroll	= {false}
					loadMore		= {this.props.loadMore}
					hasMore			= {this.props.hasMore}
					loader			= {<Spinner/>}
				>
					{this.props.conversations.map((c) =>
						<ConversationSelectorItem
							conversation	= {c}
							key				= {c.id}
							onClick			= {() => this.onConversationClick(c)}
							active			= {this.isConversationActive(c)}
							extraContacts	= {this.props.extraContacts}
						/>
					)}
				</InfiniteScroller>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSelector)