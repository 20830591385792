// NOTE: This whole file is coppied from voicemails and replaced 'voicemail(s)' with 'call(s)'. Each functio should be reviewed when worked on it.

export function currentCall(state=null, action) {
	switch (action.type) {
		case 'SWITCH_CALL':
			let callId = action.call ? action.call.id : null
			sessionStorage.setItem('session_call_id', callId)
			let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
			if (callId) {
				window.history.replaceState(`CallId: ${callId}`, 'Call Switched', `/e${extensionId}/calls/c${callId}`)
			} else {
				window.history.replaceState(`No callId`, 'Call Switched', `/e${extensionId}/calls`)
			}
			return action.call ? {...action.call} : null
		default:
			return state
	}
}

export function calls(state = {}, action) {
	let calls		= state
	let call		= null
	let callItems	= null
	switch (action.type) {
		case 'UPDATE_CALLS':
			calls = action.calls
			return {...calls}
		case 'UPDATE_CALL':
			call = action.call
			let callIndex = calls.items.findIndex(v => v.id === call.id)
			if (callIndex >= 0) calls.items[callIndex] = call
			return {...calls}
		case 'DELETE_CALL':
			calls.total--
			if (action.call.is_new) calls.unread--
			calls.items = calls.items.filter(v => v.id !== action.call.id)
			return {...calls}
		case 'ADD_CALL':
			call = action.call
			calls.total++
			if (call.is_new) calls.unread++
			calls.items.unshift(call)
			return {...calls}
		case 'ADD_RECORDINGS':
			calls.items.forEach(c => {
				delete c.recording.loading
				delete c.voicemail.loading
			})
			let recordings = action.recordings
			recordings.recordings.forEach(recording => {
				if (!recording.url) return
				let call = calls.items.find(c => c.recording.id === recording.id)
				if (!call) return
				call.recording.url = recording.url
			})
			recordings.voicemails.forEach(voicemail => {
				if (!voicemail.url) return
				let call = calls.items.find(c => c.voicemail.id === voicemail.id)
				if (!call) return
				call.voicemail.url = voicemail.url
			})
			return {...calls}
		case 'ADD_CONTACTS_TO_CALLS':
			calls		= state
			callItems	= calls.items
			callItems.forEach(c => {
				let direction = c.type === 'outgoing' ? 'to' : 'from'
				if (c[direction].contact_id) return
				let foundContact = null
				action.contacts.forEach(cd => {
					if (foundContact) return
					let foundNumber = cd.numbers.find(n => n.number === c[direction].number)
					if (foundNumber) foundContact = cd
				})
				if (!foundContact) return
				c[direction].contact_id = foundContact.id
			})
			return {...calls}
		case 'REMOVE_CONTACT_FROM_CALLS':
			let contactId	= action.contactId
			calls			= state
			callItems		= calls.items
			callItems.forEach(c => {
				let direction = c.type === 'outgoing' ? 'to' : 'from'
				if (c[direction].contact_id === contactId) delete c[direction].contact_id
			})
			return {...calls}
		default:
			return state
	}
}