import React, { Component } from 'react'

import { connect } from 'react-redux'
import {
	updateMessages, updateMediaInfo, updateConversationTotal, switchSendNumber, updateConversations,
	updateConversation, switchConversation, addContactsToConversations, removeContactFromConversations
} from '../../actions/conversations.js'

import api from '../../util/api_v2'

import ConversationHeader from './ConversationHeader'
import ConversationMessages from './ConversationMessages'
import SendMessage from './SendMessage'
import ParticipantsModal from 'participants-modal'
import LoaderFull from 'loader-full'
import PDCOpenConnection from 'pdc-open-connection'
import ReactResizeDetector from 'react-resize-detector'
import PhoneComUser from 'phone-com-user'
import EditContactModal from 'edit-contact-modal'

const mapStateToProps = state => {
	return {
		conversations:	state.conversations,
		sendNumber:		state.selectedSendNumber,
		smallView:		state.smallView
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateMessages:					(messages, conv_id)		=> dispatch(updateMessages(messages, conv_id)),
		updateMediaInfo:				(mediaInfo, conv_id)	=> dispatch(updateMediaInfo(mediaInfo, conv_id)),
		updateTotal:					(total, conv_id)		=> dispatch(updateConversationTotal(total, conv_id)),
		switchSendNumber:				num						=> dispatch(switchSendNumber(num)),
		updateConversations:			convs					=> dispatch(updateConversations(convs)),
		updateConversation:				conv					=> dispatch(updateConversation(conv)),
		switchConversation:				conv					=> dispatch(switchConversation(conv)),
		addContactsToConversations:		contacts				=> dispatch(addContactsToConversations(contacts)),
		removeContactFromConversations:	contactId				=> dispatch(removeContactFromConversations(contactId))
	}
}

class ConversationContent extends Component {

	constructor(props) {
		super(props)
		this.state = {
			messages:					[],
			loading:					false,
			hasMoreMessages:			false,
			refreshIntervalIsSet:		false,
			toNumbers:					this.props.conversation.to.map(t => t ? t.number : null),
			messageToBeSent:			null,
			hoverOverParticipants:		false,
			showConfigureBridgePopup:	false,
			editContact:				null
		}
		PDCOpenConnection.onReconnect(this.reloadMessages)
		this.markingRead = false
	}

    componentDidMount() {
        this._ismounted = true;
        if (this.props.conversation) {
            if (!this.props.conversation.mediaInfo) {
                this.updateMediaInfoState(0, [], {first_cursor: 0, last_cursor: 0})
            }
            this.loadInitialMessages(true);
            if (this.props.conversation.from[0]) {
                this.props.switchSendNumber(this.props.conversation.from[0].number);
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        function areArraysDifferent(a1, a2) {

            if (a1.length !== a2.length) {
                return true;
            }
    
            let response = false;
            a1.forEach(e => {
                if (!a2.includes(e)) {
                    response = true;
                }
            });
    
            return response;
        }

        if (
            !prevState.conversation ||
            nextProps.conversation.id !== prevState.conversation.id ||
            areArraysDifferent(nextProps.conversation.to.map(t => t.number), prevState.conversation.to.map(t => t.number))
        ) {
            return {
                conversation: nextProps.conversation,
                loading: true
            }
        }
        return {};
    }

	componentDidUpdate(prevProps) {

		this.updateEditContactIfNeeded(prevProps)

        let currentConversation		= this.props.conversation
		if (currentConversation && currentConversation.scrollToMessage) {
            let scrollElement			= document.getElementsByClassName('scroll-here')[0]
            let contentItems            = document.getElementById('content-items')
            if (contentItems) {
                let infiniteScroller		= contentItems.childNodes[0]
                infiniteScroller.scrollTop	= scrollElement.offsetTop - 75
                currentConversation.scrollToMessage = null
                this.props.updateConversation(currentConversation)
            }
        }

        if (currentConversation && !currentConversation.mediaInfo) {
            this.updateMediaInfoState(0, [], {first_cursor: 0, last_cursor: 0})
        }

        if (this.state.loading) {
            this.loadInitialMessages();
        }

        let from_numbers = currentConversation.from.map(f => f ? f.number : null);
        let first_from = currentConversation.from[0];
        if (!from_numbers.includes(this.props.sendNumber)) {
            this.props.switchSendNumber(first_from ? first_from.number : null);
        }

        let conversation = this.props.conversation;
        if (conversation.messages && conversation.messages.filter(m => m.read_at === null).length && this.props.userActive) {
            let read_at = `${(new Date()).getTime()}`;
            read_at = parseInt(read_at.substring(0, read_at.length - 3), 10);

            if (this.markingRead) {
                return;
            }

            this.markingRead = true;
            let me = this;
            if (!currentConversation.pauseMarkingRead) {
                currentConversation.messages.forEach(m => {
                    if (!m.read_at) {
                        m.read_at = parseInt((new Date()).getTime()/1000)
                    }
                })
                currentConversation.markedUnreadAt = null
                this.props.updateConversation(currentConversation)
                api.markRead(currentConversation.id, read_at)
                    .then(response => {
                        if (response.data && response.data.status === 'OK') {
                            conversation.messages.forEach(m => {
                                if (m.read_at === null) {
                                    m.read_at = read_at;
                                }
                            });
                            conversation.unread_messages = 0;
                        }
                        me.markingRead = false;
                    }, error => {
                        me.markingRead = false;
                    })
            }
		}
		
		if (this.props.deletedMessages.randomString !== prevProps.deletedMessages.randomString && this.props.deletedMessages.num) {
			this.loadMoreMessages(this.props.deletedMessages.num)
		}
	}

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        this._ismounted = false;
	}
	
	updateEditContactIfNeeded = prevProps => {
		if (prevProps.extraContacts.length === this.props.extraContacts.length || !this.state.editContact || this.state.editContact.id) return
		let editContact	= this.state.editContact
		let number		= editContact.number
		let contactId	= null
		this.props.extraContacts.forEach(cd => {
			if (cd.numbers.find(n => n.number === number)) contactId = cd.id
		})
		if (!contactId) return
		editContact.id = contactId
		this.setState({editContact})
	}

    reloadMessages = () => {
        if (this._ismounted) {
            this.loadInitialMessages();
        }
    }

    loadInitialMessages = onMount => {
        // Don't refresh if there are already messages stored
        if (this.props.conversation.messages && this.props.conversation.checkedMessages && this._ismounted) {
            this.setState({
                loading: false,
                hasMoreMessages: true
            });
            return;
        }

        let conversationBeforeLoad = this.props.conversation;

        let filters = {};
        if (this.props.conversation.id) {
            filters = {
                conversation_id: {
                    "in": this.props.conversation.id
                }
            };
        } else {
            filters = {
                participants: this.props.conversation.participants.map(p => p.number).filter(p => p)
            }
        }

        api.loadMessages(0, 30, filters)
            .then(response => {
                if (onMount === true) {
                    this.props.onLoaded()
                }

                if (conversationBeforeLoad !== this.props.conversation) return;

                let messages = response.messages;
                let total = response.total;

                if (!this.props.conversation.id) {
                    let foundConversation;
                    let conversations = this.props.conversations;
                    conversations.forEach(conversation => {

                        if (foundConversation) {
                            return;
                        }

                        let conversationParticipantNumbers = conversation.participants.map(p => p.number);
                        let thisParticipantsNumbers = this.props.conversation.participants.map(p => p.number);

                        if (conversationParticipantNumbers.length !== thisParticipantsNumbers.length) {
                            return;
                        }

                        foundConversation = conversation;
                        thisParticipantsNumbers.forEach(phoneNumber => {
                            if (!conversationParticipantNumbers.includes(phoneNumber)) {
                                foundConversation = null;
                            }
                        });
                    });

                    if (foundConversation) {
                        foundConversation.id = response.filters.conversation_id;
                        let lastMessage = response.messages.length ? response.messages[response.messages.length - 1] : {};
                        foundConversation.last_message = lastMessage

                        if (window.location.pathname.split('/')[3] !== 'new-conversation') {
                            // By this the conversation id will be added to the pathname
                            this.props.switchConversation(foundConversation)
                        }
                    }
                }

                this.storeMedia(messages, response.mediaIndexes)
                this.props.updateMessages(messages, this.props.conversation.id);
                this.props.updateTotal(total, this.props.conversation.id);
                if (this._ismounted) {
                    this.setState({
                        messages,
                        loading: false,
                        hasMoreMessages: messages.length < total
                    })
                }
            });
    };

    generateModalContentData(url) {

        if (!this.props.conversation.mediaInfo) return null

        let conversation = this.props.conversation;
        conversation.mediaInfo.media.forEach(item => {
            item.selected = item.url === url
        })
        return conversation.mediaInfo
	}

	loadMoreMessages = (limit=30) => {
		let messages = this.props.conversation.messages
		if (!messages || !messages.length) return new Promise(() => {})
		let earliestMsgTime = messages[0].created_at
		let filters = {
			conversation_id:	{in: this.props.conversation.id},
			created_before:		earliestMsgTime
		}
		return api.loadMessages(0, limit, filters)
			.then(response => {
				messages.unshift(...response.messages)

				let total = response.total
				response.mediaIndexes.first_cursor = this.props.conversation.mediaInfo.firstCursor
				this.storeMedia.bind(this)(messages, response.mediaIndexes)
				this.props.updateMessages(messages, this.props.conversation.id)
				this.props.updateTotal(total, this.props.conversation.id)
				if (this._ismounted) {
					this.setState({
						messages,
						hasMoreMessages: response.messages.length === limit
					})
				}
			})
	}

    storeMedia = (messages, mediaIndexes) => {
        let me = this
        let media = []
        messages.filter(m => m.media.length).forEach(m => {
            m.media.forEach(md => {
                md.created_at = m.created_at
                md.from = m.from
                md.message_id = m.message_id
            })
            media = media.concat(m.media)
        });
        // Load media in order to see total number of media
        let filters = {
            "file_type": "not-in:smil"
        };
        api.loadMedia(me.props.conversation.id, filters, null, null, 0, 1)
            .then(response => {
                // Set width and height of all media if it is image
                media.forEach(m => {
                    if (m.type.substring(0, 5) === 'image' && !m.width) {
        
                        let image = new Image();
                        image.onload = function() {

                            m.width = this.width;
                            m.height = this.height;
                            me.updateMediaInfoState(response.total, media, mediaIndexes);
                        }
                        image.src = m.url;
                    } else {
                        me.updateMediaInfoState(response.total, media, mediaIndexes);
                    }
                });

                if (!media.length) {
                    me.updateMediaInfoState(0, [], {first_cursor: 0, last_cursor: 0});
                }
            });
    }

    updateMediaInfoState = (total, media, mediaIndexes) => {

        let mediaInfo = {
            media:          media,
            total:          media.length,
            conversationId: this.props.conversation.id,
            dbTotal:        total,
            lastCursor:     mediaIndexes.last_cursor,
            firstCursor:    mediaIndexes.first_cursor,
            offset:         0
        }

        this.props.updateMediaInfo(mediaInfo, this.props.conversation.id);
    }

    storeNewMedia(messageResponse) {

        let me = this;
        let mediaInfo = me.props.conversation.mediaInfo;

        messageResponse.media.forEach(m => {
            if (m.type.substring(0, 5) === 'image' && !m.width) {

                let newMedia = {
                    created_at: messageResponse.created_at,
                    size: m.size,
                    type: m.type,
                    url: m.url,
                    from: messageResponse.from
                }

                let image = new Image();
                image.onload = function() {

                    newMedia.width = this.width;
                    newMedia.height = this.height;

                    if (!mediaInfo) {
                        console.error(`Media info not initialized for conversation id: ${me.props.conversation.id} yet`);
                        return;
                    }

                    mediaInfo.media.push(newMedia);
                    mediaInfo.offset++;
                    mediaInfo.dbTotal++;
                    mediaInfo.total++;

                    me.props.updateMediaInfo(mediaInfo, me.props.conversation.id);
                }
                image.src = newMedia.url;
            }
        });
    };

	renderConversationMessages() {
		return (
			<ConversationMessages
				messages					= {this.props.conversation.messages || []}
				loadMore					= {this.loadMoreMessages}
				hasMoreMessages				= {this.state.hasMoreMessages}
				isGroup						= {Object.keys(this.props.conversation.participants).length > 1}
				participants				= {this.props.conversation.participants}
				openModal					= {this.props.openModal}
				generateModalContentData	= {this.generateModalContentData.bind(this)}
				setMessageToBeSent			= {this.setMessageToBeSent}
				extraContacts				= {this.props.extraContacts}
				onMessageDeleted			= {() => this.loadMoreMessages(1)}
			/>
		)
	}

    changeNumber = num => {
        return new Promise(resolve => {
            this.props.switchSendNumber(num)
            resolve(true)
        })
    }

    setMessageToBeSent = (message) => {
        this.setState({
            messageToBeSent: message
        });
    }

    removeMessageToBeSent = () => {
        this.setState({
            messageToBeSent: null
        });
    }

	toggleConfigureBridgePopup = showConfigureBridgePopup => {
		this.setState({showConfigureBridgePopup})
		if (showConfigureBridgePopup) {
			setTimeout(() => {this.setState({showConfigureBridgePopup: false})}, 10000) // Have it shown 10 seconds if not clicked
		}
	}

    renderConfigureBridgePopup() {
        return this.state.showConfigureBridgePopup ? (
            <div className='configure-bridge-popup'>
                <a href={PhoneComUser.getControlPanelConferenceUrl()} target='_blank' onClick={() => this.toggleConfigureBridgePopup(false)}>
                    <span>Enable video integration on the extensions conferencing page</span>
                </a>
            </div>
            ) : null
    }

	startChat = phoneNumber => {
		let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
		let redirectPath = `/e${extensionId}/messages/new-conversation/${phoneNumber}`
		this.props.redirect(redirectPath)
	}

	editContact = (id, number) => {
		this.setState({editContact: {id, number: id ? '' : number}})
	}

	toggleParticipantsHover = hoverOverParticipants => {
		if (this.state.hoverOverParticipants !== hoverOverParticipants) {
			this.setState({hoverOverParticipants})
		}
	}

	renderAllParticipants() {
		let fromNumbers		= this.props.conversation.from
		let recipients		= JSON.parse(JSON.stringify(this.props.conversation.to))
		let extraContacts	= this.props.extraContacts
		recipients.forEach(r => {
			r.contactId = r.voip_contact_id
			delete r.voip_contact_id
			r.name = ''
			extraContacts.forEach(c => {
				if (r.name) return
				if (r.contactId && c.id !== r.contactId) return
				if (r.contactId || c.numbers.map(n => n.number).includes(r.number)) {
					r.name = c.name.display
				}
			})
		})
		fromNumbers.forEach(n => {
			let extensionPhoneNumbers = this.props.extension.phone_number
			n.numberNickname = extensionPhoneNumbers[n.number] ? extensionPhoneNumbers[n.number]['name'] : ''
		})

		return (
			<ParticipantsModal
				selectedNumber		= {this.props.sendNumber}
				myNumbers			= {fromNumbers}
				otherNumbers		= {recipients}
				participantsHovered	= {this.state.hoverOverParticipants}
				changeNumber		= {this.changeNumber}
				startChat			= {this.startChat}
				editContact			= {this.editContact}
			/>
		)
	}

    messagesSectionScrolled = () => {
        let messagesSectionInfiniteScroller = document.getElementsByClassName('conversation-messages-wrapper')[0].children[0].children[0]
        this.scrollDetails = {
            scrollTop:      messagesSectionInfiniteScroller.scrollTop,
            scrollHeight:   messagesSectionInfiniteScroller.scrollHeight,
            offsetHeight:   messagesSectionInfiniteScroller.offsetHeight
        }
    }

    messagesSectionResized = () => {
        if (!this.scrollDetails) return
        let messagesSectionInfiniteScroller = document.getElementsByClassName('conversation-messages-wrapper')[0].children[0].children[0]
        let offsetChange = this.scrollDetails.offsetHeight - messagesSectionInfiniteScroller.offsetHeight
        messagesSectionInfiniteScroller.scrollTop += offsetChange
        this.scrollDetails = {
            scrollTop:      messagesSectionInfiniteScroller.scrollTop,
            scrollHeight:   messagesSectionInfiniteScroller.scrollHeight,
            offsetHeight:   messagesSectionInfiniteScroller.offsetHeight
        }
	}

	getContact = () => {
		let extraContacts	= this.props.extraContacts
		let editContact		= this.state.editContact
		let contactId		= editContact ? editContact.id : null
		if (!editContact || !contactId || !extraContacts) return null
		let contact			= null
		this.props.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
		return contact
	}

	saveContact = contact => {
		let extraContacts	= this.props.extraContacts
		let isNew			= !Boolean(extraContacts.find(c => c.id === contact.id))
		this.props.updateContact(contact)
		if (isNew) this.props.addContactsToConversations([contact])
		this.setState({editContact: null})
	}

	deleteContact = contactId => {
		this.props.removeContactFromConversations(contactId)
		this.props.deleteContact(contactId)
	}

    render() {
        return (
            <div className={'conversation-content' + (this.props.smallView ? ' mobile-view' : '')}>
                <ConversationHeader
					currentConversation			= {this.props.conversation}
					setMessageToBeSent			= {this.setMessageToBeSent}
					participants				= {this.props.conversation.to}
					toggleParticipantsHover		= {this.toggleParticipantsHover}
					toggleConfigureBridgePopup	= {this.toggleConfigureBridgePopup}
					updateUnreadCounts			= {this.props.updateUnreadCounts}
					changeMessageReadStatus		= {this.props.changeMessageReadStatus}
					onConversationDeleted		= {this.props.onConversationDeleted}
					extraContacts				= {this.props.extraContacts}
				/>

                {this.renderAllParticipants()}
                {this.renderConfigureBridgePopup()}

                <div onScroll={this.messagesSectionScrolled} className="conversation-messages-wrapper">
                    {this.state.loading ? <LoaderFull/> : this.renderConversationMessages()}
                    <ReactResizeDetector handleWidth={false} handleHeight={true} onResize={this.messagesSectionResized}></ReactResizeDetector>
                </div>

                <SendMessage
                    participantNumbers={this.props.conversation.participants.map(p => p.number)}
                    to_numbers={this.state.toNumbers}
                    conversation={this.props.conversation}
                    messageToBeSent={this.state.messageToBeSent}
                    removeMessageToBeSent={this.removeMessageToBeSent}
                />

				<EditContactModal
					type				= {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
					onClose				= {() => this.setState({editContact: null})}
					fixedNumber			= {this.state.editContact ? this.state.editContact.number : null}
					contact				= {this.getContact()}
					contactGroupTypes	= {this.props.contactGroupTypes}
					saveContact			= {this.saveContact}
					deleteContact		= {this.deleteContact}
				/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationContent)