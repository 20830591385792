import Api from 'api'

export const loadContacts = async communicator => {
	let isLoading = communicator.state.loadingContacts
	// We currently have 'isLoading' because we want the contacts to get reloaded on app change
	// Once we have push notifications for contacts changes we will not need to reload them on app change
	// and can remove anything that is connected to loadingContacts state
	if (isLoading) return
	let limit = parseInt(window.innerHeight / 50)
	communicator.setState({loadingContacts: true})
	let contactsResponse = await Api.loadContacts({}, limit) || {items:[], total: 0}
	let contacts = {
		items:		contactsResponse.items,
		total:		contactsResponse.total,
		hasMore:	contactsResponse.items.length < contactsResponse.total
	}
	let contactsInfo		= communicator.state.contactsInfo
	contactsInfo.contacts	= contacts
	addToExtraContacts(contactsInfo, contacts)
	communicator.setState({contactsInfo, loadingContacts: false})
}

export const loadMore = async communicator => {
	let contactsInfo	= communicator.state.contactsInfo
	let contacts		= contactsInfo.contacts
	if (!contacts) return console.error('No contacts loaded')
	contacts			= JSON.parse(JSON.stringify(contacts))
	let contactItems	= contacts.items
	if (contactItems.length === 0) return console.warn('No contacts loaded')
	let cursor				= contactItems[contactItems.length - 1].cursor
	let contactsResponse	= await Api.loadContacts({}, 10, cursor)
	contacts.items.push(...contactsResponse.items)
	contacts.hasMore		= contactsResponse.items.length < contactsResponse.total
	contactsInfo.contacts	= contacts
	addToExtraContacts(contactsInfo, contacts)
	communicator.setState({contactsInfo})
}

function addToExtraContacts(contactsInfo, contacts) {
	let extraContacts	= JSON.parse(JSON.stringify(contactsInfo.extraContacts))
	let contactItems	= JSON.parse(JSON.stringify(contacts.items)).filter(c => !extraContacts.find(ec => ec.id === c.id))
	extraContacts.push(...contactItems)
	contactsInfo.extraContacts = extraContacts
}

// We need to know if the extension has a contact for each loaded conversation/fax/voicemail/call.
// These contacts are kept in communicator.state.extraContacts.
// They are not in state.contacts because state.contacts should have ordered contacts list.
export const loadExtraContacts = async (communicator, filters) => {
	let contactsInfo			= communicator.state.contactsInfo
	let contactsResponse		= await Api.loadContacts(filters)
	let newContactsData			= contactsResponse.items
	let groupTypes				= contactsResponse.group_types
	let extraContacts			= JSON.parse(JSON.stringify(contactsInfo.extraContacts))
	extraContacts.push(...newContactsData)
	contactsInfo.extraContacts	= extraContacts
	contactsInfo.groupTypes		= groupTypes
	communicator.setState({contactsInfo})
	return newContactsData
}

export const updateExtraContacts = (communicator, extraContacts) => {
	let contactsInfo = JSON.parse(JSON.stringify(communicator.state.contactsInfo))
	contactsInfo.extraContacts = extraContacts
	communicator.setState({contactsInfo})
}

export const updateContact = (communicator, contact) => {
	let contactsInfo	= JSON.parse(JSON.stringify(communicator.state.contactsInfo))
	let extraContacts	= JSON.parse(JSON.stringify(contactsInfo.extraContacts))
	let contacts		= JSON.parse(JSON.stringify(contactsInfo.contacts))
	let contactId		= contact.id

	// Update the extraContacts data
	let isNew			= true
	extraContacts.forEach((c, i) => {
		if (c.id !== contactId) return
		extraContacts[i]	= contact
		isNew				= false
	})
	if (isNew) {
		contact.numbers.forEach(n => delete n.fixed)
		extraContacts.push(contact)
	}
	contactsInfo.extraContacts = extraContacts

	// Update the contacts data
	let isLoadedContact = false
	contacts.items.forEach((c, i) => {
		if (c.id !== contactId) return
		isLoadedContact		= true
		contacts.items[i]	= contact
	})
	if (!isLoadedContact) {
		let indexPush = contacts.items.findIndex(c => c.name.display >= contact.name.display)
		if (indexPush !== -1) contacts.items.splice(indexPush, 0, contact)
		else contacts.items.push(contact)
	}
	contactsInfo.contacts = contacts

	communicator.setState({contactsInfo})
}

export const deleteContact = (communicator, contactId) => {
	let contactsInfo			= JSON.parse(JSON.stringify(communicator.state.contactsInfo))
	let contacts				= contactsInfo.contacts
	let contactItems			= contacts.items
	let extraContacts			= contactsInfo.extraContacts
	let deleteContactIndex		= contactItems.findIndex(c => c.id === contactId)
	if (deleteContactIndex !== -1) contactItems.splice(deleteContactIndex, 1)
	let deleteExtraContactIndex	= extraContacts.findIndex(c => c.id === contactId)
	if (deleteExtraContactIndex !== -1) extraContacts.splice(deleteExtraContactIndex, 1)
	contactsInfo.contacts		= contacts
	contactsInfo.extraContacts	= extraContacts
	communicator.setState({contactsInfo})
}